import React from "react";
import {
  ShieldCheckIcon,
  DocumentTextIcon,
  CheckBadgeIcon,
  BriefcaseIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/16/solid";

const Finance = () => {
  const items = [
    {
      icon: <ShieldCheckIcon className="w-6 h-6 text-green-500" />,
      text: "Financiënreglement",
    },
    {
      icon: <DocumentTextIcon className="w-6 h-6 text-green-500" />,
      text: "Leningen & subsidies",
    },
    {
      icon: <ClipboardDocumentCheckIcon className="w-6 h-6 text-green-500" />,
      text: "Belastingen",
    },
    {
      icon: <CheckBadgeIcon className="w-6 h-6 text-green-500" />,
      text: "Ondernemingen & Beleid",
    },
    {
      icon: <DocumentTextIcon className="w-6 h-6 text-green-500" />,
      text: "BBI | Belastingsinspectie",
    },
    {
      icon: <BriefcaseIcon className="w-6 h-6 text-green-500" />,
      text: "Werken bij financiën",
    },
  ];

  return (
    <div className="flex-1 mx-auto p-4 bg-[#2b2b2b] animate-fadeIn delay-3s rounded-xl shadow-xl">
      <div className="flex items-center justify-center w-full p-2">
        <h1 className="text-2xl font-bold text-white">Departement</h1>
      </div>
      <div className="flex items-center justify-center w-full p-4 bg-green-500 rounded-xl">
        <h1 className="font-bold text-white text-1xl sm:text-3xl">Financiën</h1>
      </div>
      <div className="w-full p-4 rounded-xl">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex items-center p-4 transition-colors duration-200 bg-gray-800 rounded-xl hover:bg-gray-700">
              {item.icon}
              <p className="ml-4 text-white">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Finance;
