import React from "react";
import {
  ShieldCheckIcon,
  DocumentTextIcon,
  CheckBadgeIcon,
  BriefcaseIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/16/solid";

const Police = () => {
  const items = [
    {
      icon: <ShieldCheckIcon className="w-6 h-6 text-blue-500" />,
      text: "Aangifte",
    },
    {
      icon: <DocumentTextIcon className="w-6 h-6 text-blue-500" />,
      text: "Politiereglement",
    },
    {
      icon: <ClipboardDocumentCheckIcon className="w-6 h-6 text-blue-500" />,
      text: "Strafregister",
    },
    {
      icon: <CheckBadgeIcon className="w-6 h-6 text-blue-500" />,
      text: "Boetes",
    },
    {
      icon: <DocumentTextIcon className="w-6 h-6 text-blue-500" />,
      text: "Vergunningen",
    },
    {
      icon: <BriefcaseIcon className="w-6 h-6 text-blue-500" />,
      text: "Werken bij de politie",
    },
  ];

  const employees = [
    {
      name: "_JustMichael",
      role: "Korpschef",
      avatar: "https://mc-heads.net/Avatar/_JustMichael",
    },
    {
      name: "Notch",
      role: "Hoofdagent",
      avatar: "https://mc-heads.net/Avatar/notch",
    },
    {
      name: "Steve",
      role: "Agent",
      avatar: "https://mc-heads.net/Avatar/steve",
    },
    {
      name: "Alex",
      role: "Agent",
      avatar: "https://mc-heads.net/Avatar/alex",
    },
  ];

  return (
    <div className="flex-1 mx-auto p-4 bg-[#2b2b2b] animate-fadeIn delay-3s rounded-xl shadow-xl">
      <div className="flex items-center justify-center w-full p-2">
        <h1 className="text-2xl font-bold text-white">Departement</h1>
      </div>
      <div className="flex items-center justify-center w-full p-4 bg-blue-600 rounded-xl">
        <h1 className="font-bold text-white text-1xl sm:text-3xl">
          Justitie & Veiligheid
        </h1>
      </div>
      <div className="w-full p-4 rounded-xl">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex items-center p-4 transition-colors duration-200 bg-gray-800 rounded-xl hover:bg-gray-700">
              {item.icon}
              <p className="ml-4 text-white">{item.text}</p>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 gap-6 py-10 sm:grid-cols-2">
          <div className="space-y-4">
            <h2 className="text-2xl font-bold text-white">Laatste nieuws</h2>
            <div className="p-4 bg-gray-800 rounded-xl">
              <h3 className="text-xl font-semibold text-blue-400">
                Nieuw politiereglement
              </h3>
              <p className="text-gray-300">
                Het nieuwe politiereglement is vanaf vandaag van kracht. Lees
                het reglement goed door om op de hoogte te zijn van de regels.
              </p>
            </div>
            <div className="p-4 bg-gray-800 rounded-xl">
              <h3 className="text-xl font-semibold text-blue-400">
                Nieuwe politiechef
              </h3>
              <p className="text-gray-300">
                Wij verwelkomen onze nieuwe politiechef, Michael. Wij wensen hem
                veel succes in zijn nieuwe functie.
              </p>
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-2xl font-bold text-white">Medewerkers</h2>
            {employees.map((employee, index) => (
              <div
                key={index}
                className="flex items-center gap-4 p-4 bg-gray-800 rounded-xl">
                <img
                  src={employee.avatar}
                  alt={`${employee.name} avatar`}
                  className="w-10 h-10 rounded-full md:w-15 md:h-15"
                />
                <div>
                  <h3 className="text-xl font-semibold text-blue-400">
                    {employee.name}
                  </h3>
                  <p className="text-gray-300">{employee.role}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Police;
