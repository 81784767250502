import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import "./App.css";
import "./index.css";
import HeaderSection from "./components/Header";
import FooterSection from "./components/Footer";
import WikiMainPage from "./pages/wiki/page";
import Auth from "./Auth";
import PanelRegisterPage from "./pages/panel/register";
import PanelLoginPage from "./pages/panel/login";
import Dashboard from "./ui/panel/dashboard";
import Bedrijven from "./pages/panel/pages/companys";
import Marktplaats from "./pages/panel/pages/market";
import Home from "./pages/panel/pages/home";
import LandingScreen from "./components/Home";
import Account from "./pages/panel/pages/account";
import Berichten from "./pages/panel/pages/messages";
import Aanvragen from "./pages/panel/pages/applys";
import Opleidingen from "./pages/panel/pages/education";
import Overheid from "./pages/panel/pages/gov";
import TeamPage from "./components/Team";
import Contact from "./components/Contact";
import DashboardAdmin from "./ui/panel/admin/portal/dashboard";
import JusAdmin from "./pages/panel/pages/gov/admin/jus/home";
import BinAdmin from "./pages/panel/pages/gov/admin/bin/interior";
import EcoAdmin from "./pages/panel/pages/gov/admin/eco/economy";
import FinAdmin from "./pages/panel/pages/gov/admin/fin/finance";
import InfraAdmin from "./pages/panel/pages/gov/admin/infra/infra";
import DepartmentDashboard from "./ui/panel/admin/departments/DepartmentDashboard";
import Reports from "./pages/panel/pages/gov/admin/jus/reports";
import DashboardWiki from "./ui/wiki/dashboard";
import WikiInfoPage from "./pages/wiki/pages/info";
import WikiNewsPage from "./pages/wiki/pages/news";
import WikiLawPage from "./pages/wiki/pages/law";
import WikiCrimePage from "./pages/wiki/pages/crime";
import WikiPermitsPage from "./pages/wiki/pages/permits";
import ZoekDossier from "./pages/panel/pages/gov/admin/jus/dossier";
import Huiszoek from "./pages/panel/pages/gov/admin/jus/huiszoek";
import Collega from "./pages/panel/pages/gov/admin/jus/collega";
import Databanken from "./pages/panel/pages/gov/admin/jus/databanken";
import Intranet from "./pages/panel/pages/gov/admin/jus/intranet";
import Contributors from "./components/contributors";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthSuccess = () => {
    setIsAuthenticated(true);
  };

  if (!isAuthenticated) {
    return <Auth onAuthSuccess={handleAuthSuccess} />;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeaderSection />
              <LandingScreen />
              <FooterSection />
            </>
          }
        />
        <Route
          path="/wiki"
          element={
            <>
              <HeaderSection />
              <WikiMainPage />
              <FooterSection />
            </>
          }
        />

        <Route
          path="/contributors"
          element={
            <>
              <HeaderSection />
              <Contributors />
              <FooterSection />
            </>
          }
        />
        <Route
          path="/team"
          element={
            <>
              <HeaderSection />
              <TeamPage />
              <FooterSection />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <HeaderSection />
              <Contact />
              <FooterSection />
            </>
          }
        />

        <Route path="/overheid" element={<DashboardAdmin />} />
        <Route
          path="/overheid/department/jus"
          element={<DepartmentDashboard />}>
          <Route index element={<JusAdmin />} />
        </Route>
        <Route
          path="/overheid/department/jus/report"
          element={<DepartmentDashboard />}>
          <Route index element={<Reports />} />
        </Route>
        <Route
          path="/overheid/department/jus/zoek-dossier"
          element={<DepartmentDashboard />}>
          <Route index element={<ZoekDossier />} />
        </Route>
        <Route
          path="/overheid/department/jus/huiszoekingen"
          element={<DepartmentDashboard />}>
          <Route index element={<Huiszoek />} />
        </Route>

        <Route
          path="/overheid/department/jus/collegas"
          element={<DepartmentDashboard />}>
          <Route index element={<Collega />} />
        </Route>

        <Route
          path="/overheid/department/jus/databanken"
          element={<DepartmentDashboard />}>
          <Route index element={<Databanken />} />
        </Route>

        <Route
          path="/overheid/department/jus/intranet"
          element={<DepartmentDashboard />}>
          <Route index element={<Intranet />} />
        </Route>

        <Route
          path="/overheid/department/jus/zoek-dossier"
          element={<DepartmentDashboard />}>
          <Route index element={<ZoekDossier />} />
        </Route>
        <Route
          path="/overheid/department/bin"
          element={<DepartmentDashboard />}>
          <Route index element={<BinAdmin />} />
        </Route>
        <Route
          path="/overheid/department/eco"
          element={<DepartmentDashboard />}>
          <Route index element={<EcoAdmin />} />
        </Route>
        <Route
          path="/overheid/department/fin"
          element={<DepartmentDashboard />}>
          <Route index element={<FinAdmin />} />
        </Route>
        <Route
          path="/overheid/department/infra"
          element={<DepartmentDashboard />}>
          <Route index element={<InfraAdmin />} />
        </Route>

        <Route
          path="/wiki"
          element={
            <>
              <HeaderSection />
              <DashboardWiki />
              <FooterSection />
            </>
          }>
          <Route path="/wiki" element={<WikiMainPage />} />
          <Route path="info" element={<WikiInfoPage />} />
          <Route path="nieuws" element={<WikiNewsPage />} />
          <Route path="wetboek" element={<WikiLawPage />} />
          <Route path="strafboek" element={<WikiCrimePage />} />
          <Route path="vergunningen" element={<WikiPermitsPage />} />
        </Route>

        <Route path="/paneel" element={<Dashboard />}>
          <Route path="/paneel" element={<Home />} />
          <Route path="overheid" element={<Overheid />} />
          <Route path="bedrijven" element={<Bedrijven />} />
          <Route path="marktplaats" element={<Marktplaats />} />
          <Route path="opleidingen" element={<Opleidingen />} />
          <Route path="aanvragen" element={<Aanvragen />} />
          <Route path="berichten" element={<Berichten />} />
          <Route path="account" element={<Account />} />
        </Route>

        <Route path="/register" element={<PanelRegisterPage />} />
        <Route path="/login" element={<PanelLoginPage />} />
      </Routes>
    </Router>
  );
}

export default App;
