import React from "react";
import { Link } from "react-router-dom";

const PanelRegisterPage = () => {
  return (
    <div className="snow">
      <div className="relative flex items-center justify-center h-screen bg-gradient-to-b from-[#3B198D] via-[#4a1cb8] to-[#1b1b1b]">
        <div className="flex flex-col w-full h-auto max-w-2xl overflow-hidden shadow-lg md:flex-row rounded-3xl animate-fade-in delay-2s">
          {/* Formulier sectie */}
          <div className="w-full p-8 md:w-2/3 bg-[#f9f9f9]">
            <form method="POST" action="paneel" className="space-y-6">
              <h1 className="text-3xl font-bold text-purple-700">ECLIPSEMC</h1>
              <p className="text-sm font-bold text-purple-600">
                Account aanmaken
              </p>

              <div className="form-group">
                <label htmlFor="mcname" className="block text-sm text-gray-600">
                  Minecraftnaam
                </label>
                <input
                  id="mcname"
                  type="text"
                  name="mcname"
                  placeholder="Minecraftnaam"
                  className="w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email" className="block text-sm text-gray-600">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="password"
                  className="block text-sm text-gray-600">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="block w-full py-2 mt-4 font-medium text-white transition duration-300 ease-in-out bg-purple-700 shadow-lg hover:bg-purple-800 rounded-xl">
                  <Link to="/paneel">Account aanmaken</Link>
                </button>
              </div>
            </form>
          </div>

          {/* Informatie sectie */}
          <div className="items-center justify-center hidden w-full p-8  bg-gradient-to-b from-[#3B198D]  to-[#4a1cb8] md:flex md:w-4/6 rounded-r-3xl">
            <div className="text-center">
              <h1 className="text-3xl font-bold">Webpaneel</h1>
              <div className="flex justify-center my-4">
                <img
                  src="/img/panel.svg"
                  alt="Webpaneel afbeelding"
                  className="h-32"
                />
              </div>
              <p className="text-lg leading-relaxed">
                Beheer je bedrijf, bekijk huizen, statistieken,
                overheidsadministratie en nog veel meer via het paneel.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SVG Waves */}
      <svg
        className="hero-waves"
        viewBox="0 24 150 28"
        preserveAspectRatio="none">
        <defs>
          <path
            id="wave-path"
            d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="wave1">
          <use
            xlinkHref="#wave-path"
            x="50"
            y="3"
            fill="rgba(255,255,255, 0.1)"
          />
        </g>
        <g className="wave2">
          <use
            xlinkHref="#wave-path"
            x="50"
            y="0"
            fill="rgba(255,255,255, 0.2)"
          />
        </g>
        <g className="wave3">
          <use
            xlinkHref="#wave-path"
            x="50"
            y="9"
            fill="rgba(255,255,255, 0.4)"
          />
        </g>
      </svg>
    </div>
  );
};

export default PanelRegisterPage;
