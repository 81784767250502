import React from "react";
import {
  UserGroupIcon,
  ShieldCheckIcon,
  BriefcaseIcon,
  CurrencyEuroIcon,
  WrenchIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

const AdminHome = () => {
  const navigate = useNavigate();

  const departments = [
    {
      title: "Justitie en Veiligheid",
      description:
        "Open werk omgeving van het departement justitie en veiligheid. Succes met je werkzaamheden.",
      icon: <ShieldCheckIcon className="w-10 h-10 text-white" />,
      href: "/overheid/department/jus",
    },
    {
      title: "Binnenlands Bestuur",
      description:
        "Open werk omgeving van het departement binnenlands bestuur. Succes met je werkzaamheden.",
      icon: <UserGroupIcon className="w-10 h-10 text-white" />,
      href: "/overheid/department/bin",
    },
    {
      title: "Economische Zaken",
      description:
        "Open werk omgeving van het departement economische zaken. Succes met je werkzaamheden.",
      icon: <BriefcaseIcon className="w-10 h-10 text-white" />,
      href: "/overheid/department/eco",
    },
    {
      title: "Financiën",
      description:
        "Open werk omgeving van het departement financiën. Succes met je werkzaamheden.",
      icon: <CurrencyEuroIcon className="w-10 h-10 text-white" />,
      href: "/overheid/department/fin",
    },
    {
      title: "Infrastructuur",
      description:
        "Open werk omgeving van het departement infrastructuur. Succes met je werkzaamheden.",
      icon: <WrenchIcon className="w-10 h-10 text-white" />,
      href: "/overheid/department/infra",
    },
    // Andere departementen...
  ];

  const handleDepartmentClick = (href) => {
    navigate(href);
  };

  return (
    <div className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b]">
      <h1 className="mb-6 text-3xl font-bold text-white">Overheidsportaal</h1>

      <div className="grid grid-cols-1 gap-6 py-10 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-5">
        {departments.map((department, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-6 transition-transform duration-300 ease-in-out transform bg-gray-800 rounded-lg shadow-lg cursor-pointer hover:scale-105"
            onClick={() => handleDepartmentClick(department.href)}>
            <div className="mb-4">{department.icon}</div>
            <h2 className="mb-2 text-xl font-semibold text-white">
              {department.title}
            </h2>
            <p className="text-center text-gray-400">
              {department.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminHome;
