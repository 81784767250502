import React, { useState } from "react";
import { PowerIcon } from "@heroicons/react/24/solid";
import NavLinks from "./nav-links";

const SideNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSelect = () => {
    setIsMenuOpen(false); // Sluit het menu na selectie
  };

  return (
    <>
      {/* Sidebar for larger screens */}
      <div className="fixed top-0 left-0 z-10 flex-col hidden w-64 h-full px-6 py-12 space-y-4 overflow-x-hidden overflow-y-scroll text-white md:flex bg-gradient-to-b from-gray-800 to-gray-900">
        <div className="flex flex-col items-center w-full p-4 space-y-4">
          {/* Profiel Afbeelding en Info */}
          <img
            src="https://mc-heads.net/Avatar/_JustMichael"
            alt="Webpaneel afbeelding"
            className="w-24 h-24 border-4 border-blue-500 rounded-full"
          />
          <div className="pb-4 text-center border-b-2">
            <p className="text-2xl font-bold">_JustMichael</p>
            <p className="px-4 py-1 mt-2 text-sm font-semibold text-white bg-blue-500 rounded-full">
              Korpschef
            </p>
            <p className="px-4 py-1 mt-2 text-sm font-semibold text-white bg-red-500 rounded-full">
              Stafflid
            </p>
          </div>
        </div>

        {/* Navigatielinks */}
        <NavLinks onSelect={handleSelect} isCollapsed={false} />

        {/* Instellingen en Uitloggen */}
        <div className="flex flex-col items-center w-full mt-4 space-y-4">
          <button className="flex items-center w-full gap-2 p-2 transition-transform duration-300 ease-in-out transform rounded-md hover:bg-red-600 hover:scale-105">
            <PowerIcon className="w-6" />
            Uitloggen
          </button>
        </div>
      </div>
    </>
  );
};

export default SideNav;
