"use client";
import clsx from "clsx";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  HomeIcon,
  BookOpenIcon,
  ChatBubbleLeftRightIcon,
  PaperClipIcon,
  NewspaperIcon,
} from "@heroicons/react/16/solid";

// Map of links to display in the side navigation.
// Depending on the size of the application, this would be stored in a database.
const links = [
  { name: "Home", href: "/wiki", icon: HomeIcon },
  {
    name: "Informatie",
    href: "/wiki/info",
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: "Nieuws",
    href: "/wiki/nieuws",
    icon: NewspaperIcon,
  },
  {
    name: "Wetboek",
    href: "/wiki/wetboek",
    icon: BookOpenIcon,
  },
  {
    name: "Strafboek",
    href: "/wiki/strafboek",
    icon: BookOpenIcon,
  },
  { name: "Vergunningen", href: "/wiki/vergunningen", icon: PaperClipIcon },
];

const NavLinks = ({ onSelect, isCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleSelect = (href) => {
    setActiveLink(href);
    onSelect(href);
    navigate(href);
  };

  return (
    <div className="flex flex-col w-full space-y-4 ">
      {links.map((link) => {
        const LinkIcon = link.icon;
        const isActive = activeLink === link.href;

        return (
          <div key={link.name}>
            <button
              onClick={() => handleSelect(link.href)}
              className={clsx(
                "flex items-center justify-center w-full p-2 rounded-md transition-transform duration-300 ease-in-out transform hover:scale-105",
                {
                  "bg-epurple-light text-white": isActive, // Active link styling
                  "text-gray-200 hover:bg-epurple-light": !isActive, // Non-active link styling
                }
              )}>
              <LinkIcon className="w-6 h-6 mr-2" />
              {!isCollapsed && <p className="block lg:block">{link.name}</p>}
            </button>
          </div>
        );
      })}
    </div>
  );
};
export default NavLinks;
