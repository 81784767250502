import React from "react";

const Main = () => {
  return (
    <div className="bg-[#00022f]">
      <div className="max-w-screen-xl px-5 py-10 mx-auto text-center">
        <h4 className="inline-block px-4 py-2 mb-5 text-white border border-white border-solid border-3 rounded-xl">
          Over ons
        </h4>

        <h1 className="py-5 text-4xl font-bold text-white">EclipseMC</h1>
        <h3 className="max-w-2xl py-5 mx-auto text-xl text-gray-300">
          EclipseMC is een Minecraft server die zich focust op een realistische
          ervaring.
        </h3>
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="max-w-sm text-center text-white">
            <h1 className="mb-2 text-2xl font-bold">Community</h1>
            <div className="flex justify-center pb-3">
              <img src="/img/community.svg" alt="team" className="h-44" />
            </div>
            <h2 className="mb-4 text-lg">
              We hebben een actieve community die altijd klaar staat om je te
              helpen. We hebben een discord server waar je altijd terecht kan
              voor hulp of gewoon een gezellige babbel.
            </h2>
          </div>
          <div className="max-w-sm text-center text-white">
            <h1 className="mb-2 text-2xl font-bold">Paneel</h1>
            <div className="flex justify-center pb-3">
              <img src="/img/panel.svg" alt="team" className="h-44" />
            </div>
            <h2 className="mb-4 text-lg">
              Via het paneel willen we aantal zaken vereenvoudigen en
              toegankelijker maken. Je kan via het paneel bijvoorbeeld je eigen
              statistieken bekijken, overheidsadministratie bekijken en nog veel
              meer!
            </h2>
          </div>
          <div className="max-w-sm text-center text-white">
            <h1 className="mb-2 text-2xl font-bold">Custom plugins</h1>
            <div className="flex justify-center pb-3">
              <img src="/img/team.svg" alt="team" className="h-44" />
            </div>
            <h2 className="mb-4 text-lg">
              Wij hebben een team met vrijwilligers die zich inzetten om het
              project te laten slagen. We hebben custom plugins die het spelen
              op onze server nog leuker maken.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
