import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import React from "react";
import { BsDiscord, BsEnvelope } from "react-icons/bs";

const Contact = () => {
  return (
    <div className="relative bg-gradient-to-b from-[#3B198D] via-[#4a1cb8] to-[#00022f]">
      <div className="relative z-20 flex flex-col items-center px-5 py-12 mx-auto lg:py-20">
        <div className="grid items-center min-h-full gap-8 md:grid-cols-2">
          {/* Left Section */}
          <div className="text-left">
            <div className="mb-10">
              <h1 className="text-3xl font-bold tracking-wide text-center sm:text-5xl animate-fade-in delay-1s lg:text-left">
                Contacteer ons
              </h1>
            </div>
            <div className="max-w-2xl">
              <div className="flex flex-col items-start justify-between mt-8">
                <p className="text-lg leading-relaxed text-center sm:text-xl xl:text-left animate-fade-in delay-3s">
                  Wij staan altijd voor u klaar! U kunt ons bereiken via de
                  volgende kanalen.
                </p>
              </div>
              <div className="flex flex-col justify-around pt-10 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 xl:justify-start">
                <div className="flex items-center space-x-2 text-xl bg-eyellow text-[#1b1b1b] p-4 rounded-full">
                  <BsEnvelope className="w-6 h-6" />
                  <span>support@eclipsemc.com</span>
                </div>
                <div className="flex items-center space-x-2 text-xl bg-eyellow text-[#1b1b1b] p-4 rounded-full">
                  <BsDiscord className="w-6 h-6" />
                  <span>discord.gg/eclipsemc</span>
                </div>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="text-center text-white ">
            <div className="flex justify-center animate-fade-in delay-5s">
              <img
                src="/img/logo-t.png"
                alt="Hero"
                width={400}
                height={400}
                className="h-[200px] w-[200px] sm:h-[400px] sm:w-[400px]"
              />
            </div>
          </div>
        </div>
        {/* Contact Form */}
        <div className="w-full max-w-2xl p-8 mt-12 rounded-lg shadow-md bg-epurple">
          <h2 className="mb-6 text-2xl font-bold ">Stuur ons een bericht</h2>
          <form>
            <div className="mb-4">
              <label className="block mb-2 text-md" htmlFor="name">
                Naam
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-epurple"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-md" htmlFor="email">
                E-mail
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-epurple"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-md" htmlFor="subject">
                Onderwerp
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-epurple"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-md" htmlFor="message">
                Bericht
              </label>
              <textarea
                id="message"
                name="message"
                rows="5"
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-epurple"
                required></textarea>
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="px-6 py-2 font-medium text-black rounded-lg bg-eyellow hover:bg-epurple focus:outline-none focus:bg-blue-700">
                Verzenden
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="custom-shape-divider-bottom-1727880601">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none">
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"></path>
        </svg>
      </div>
    </div>
  );
};

export default Contact;
