import React from "react";
import {
  BookOpenIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserGroupIcon,
  MapPinIcon,
} from "@heroicons/react/16/solid";
const Home = () => {
  const date = new Date();
  const dashboardData = {
    marktplaats: 5,
    opleidingen: 3,
    aanvragen: 7,
    berichten: 2,
  };

  return (
    <div className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b] animate-fadeIn delay-3s">
      <h1 className="mb-6 text-3xl font-bold text-white">Dashboard</h1>
      <div className="p-3 rounded-lg shadow-md bg-epurple">
        <div className="flex justify-between p-3">
          <div>
            <h3 className="text-3xl font-semibold text-white">
              Welkom, gebruiker
            </h3>
            <p className="text-gray-300 text-md">
              Maak jezelf wegwijs op de paneel. Heb je toch hulp nodig? Neem dan
              direct contact met ons op via onze Discord server.
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 py-10 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4">
        <div className="flex items-center justify-between p-6 text-white transition duration-300 transform rounded-lg shadow-md hover:shadow-lg hover:scale-105 bg-custom-gradient">
          <div>
            <h2 className="text-lg font-semibold">Marktplaats</h2>
            <p className="text-4xl font-bold">{dashboardData.marktplaats}</p>
          </div>
          <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full bg-opacity-20">
            <MapPinIcon className="w-6 h-6" />
          </div>
        </div>
        <div className="flex items-center justify-between p-6 text-white transition duration-300 transform rounded-lg shadow-md hover:shadow-lg hover:scale-105 bg-custom-gradient">
          <div>
            <h2 className="text-lg font-semibold">Opleidingen</h2>
            <p className="text-4xl font-bold">{dashboardData.opleidingen}</p>
          </div>
          <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full bg-opacity-20">
            <AcademicCapIcon className="w-6 h-6" />
          </div>
        </div>
        <div className="flex items-center justify-between p-6 text-white transition duration-300 transform rounded-lg shadow-md hover:shadow-lg hover:scale-105 bg-custom-gradient">
          <div>
            <h2 className="text-lg font-semibold">Aanvragen</h2>
            <p className="text-4xl font-bold">{dashboardData.aanvragen}</p>
          </div>
          <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full bg-opacity-20">
            <BookOpenIcon className="w-6 h-6" />
          </div>
        </div>
        <div className="flex items-center justify-between p-6 text-white transition duration-300 transform rounded-lg shadow-md hover:shadow-lg hover:scale-105 bg-custom-gradient">
          <div>
            <h2 className="text-lg font-semibold">Berichten</h2>
            <p className="text-4xl font-bold">{dashboardData.berichten}</p>
          </div>
          <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full bg-opacity-20">
            <PaperClipIcon className="w-6 h-6" />
          </div>
        </div>
      </div>

      <div className="bg-[#2b2b2b] shadow-xl rounded-xl p-4">
        <h2 className="text-2xl font-bold text-white">Laatste nieuws</h2>
        {Array(3)
          .fill()
          .map((_, index) => (
            <div
              key={index}
              className="flex justify-between p-3 border-b border-[var(--foreground)]">
              <div>
                <h3 className="text-lg font-semibold text-white">
                  Nieuws {index + 1}
                </h3>
                <p className="text-sm text-gray-400">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Consequatur voluptatibus est enim, repudiandae voluptas odio
                  officiis autem sint, odit molestiae atque vero impedit ut
                  fugiat assumenda? Consequatur alias autem eligendi!
                </p>
              </div>
              <p className="text-sm text-gray-400">
                {date.toLocaleDateString()}
              </p>
            </div>
          ))}
      </div>
      <div className="mt-10 bg-[#2b2b2b] shadow-xl rounded-xl p-4">
        <h2 className="mb-4 text-2xl font-bold text-white">
          Recente Activiteiten
        </h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus
          dolor quisquam minima! Saepe cupiditate laudantium porro. Accusamus
          quibusdam consectetur animi vitae numquam! Autem quidem, inventore
          maxime quae neque excepturi ea!
        </p>
      </div>
    </div>
  );
};

export default Home;
