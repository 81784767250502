import React from "react";

const teamMembers = [
  {
    name: "Zendé - Swurfyy",
    role: "Beheerder",
    image: "https://mc-heads.net/Avatar/Swurfyy",
  },
  {
    name: "Vincent - VincentS03",
    role: "Manager",
    image: "https://mc-heads.net/Avatar/VincentS03",
  },
  {
    name: "Michael - JustMichael23",
    role: "Webdeveloper",
    image: "https://mc-heads.net/Avatar/_JustMichael",
  },
  {
    name: "Emily Davis",
    role: "Designer",
    image: "https://mc-heads.net/Avatar/",
  },
  // Voeg hier meer teamleden toe
];

const vacatures = [
  {
    title: "Java developers",
    description:
      "We zijn op zoek naar een getalenteerde Java developer om ons team te versterken.",
    image: "https://via.placeholder.com/150",
  },
  {
    title: "Scripters (Skript)",
    description:
      "We zijn op zoek naar een ervaren Skript scripter om ons team te versterken.",
    image: "https://via.placeholder.com/150",
  },
  {
    title: "Resouecepack Designer",
    description:
      "We zoeken creatieve resourcepack designers, je maak blocks, gear setjes enz...",
    image: "https://via.placeholder.com/150",
  },
  {
    title: "Builders",
    description: "We zoeken ervaren builders die ons team kunnen versterken.",
    image: "https://via.placeholder.com/150",
  },

  // Voeg hier meer vacatures toe
];

const Team = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#3B198D] via-[#4a1cb8] to-[#00022f] p-6">
      <div className="max-w-6xl mx-auto text-center">
        <h1 className="mb-12 text-4xl font-bold text-white">Ons Team</h1>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg">
              <img
                src={member.image}
                alt={member.name}
                className="w-32 h-32 mb-4 rounded-full"
              />
              <h2 className="text-xl font-semibold text-gray-800">
                {member.name}
              </h2>
              <p className="text-gray-600">{member.role}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-6xl mx-auto mt-12 text-center">
        <h1 className="mb-12 text-4xl font-bold text-white">Vacatures</h1>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {vacatures.map((vacature, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg">
              <img
                src={vacature.image}
                alt={vacature.title}
                className="w-32 h-32 mb-4 rounded-full"
              />
              <h2 className="text-xl font-semibold text-gray-800">
                {vacature.title}
              </h2>
              <p className="mb-4 text-gray-600">{vacature.description}</p>
              <button className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:bg-blue-700">
                Solliciteer
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
