import React from "react";

const courses = [
  {
    id: 1,
    title: "Bedrijfseconomie",
    description:
      "Leer de basisprincipes van bedrijfseconomie en hoe je een bedrijf kunt starten en beheren.",
    duration: "6 maanden",
  },
  {
    id: 2,
    title: "Financieel Management",
    description:
      "Begrijp de financiële aspecten van het runnen van een bedrijf, inclusief budgettering en financiële planning.",
    duration: "5 maanden",
  },
  {
    id: 3,
    title: "Politie kaderopleiding",
    description:
      "Leer de basisprincipes van het politiewerk en hoe je een politieagent kunt worden.",
    duration: "5 maanden",
  },
  // Add more courses as needed
];

const Opleidingen = () => {
  return (
    <div className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b] animate-fadeIn delay-3s">
      <h1 className="mb-6 text-3xl font-bold text-white">Opleidingen</h1>
      <div className="space-y-8">
        {courses.map((course) => (
          <div key={course.id} className="p-6 bg-[#2c2c2c] rounded-lg">
            <h2 className="mb-2 text-2xl font-semibold text-yellow-500">
              {course.title}
            </h2>
            <p className="mb-2 text-white">{course.description}</p>
            <p className="mb-4 text-gray-400">Duur: {course.duration}</p>
            <button className="px-4 py-2 font-medium rounded-lg bg-epurple hover:bg-epurple-light">
              Meer informatie
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Opleidingen;
