import React, { useState } from "react";

const JusAdmin = () => {
  // State voor modals en gegevens
  const [showAddModal, setShowAddModal] = useState(false); // Voor het aanmaken van rapporten
  const [showViewModal, setShowViewModal] = useState(false); // Voor het bekijken van rapporten
  const [selectedReport, setSelectedReport] = useState(null); // Geselecteerd rapport om te bekijken
  const [reports, setReports] = useState([]);
  const [newReport, setNewReport] = useState({
    firstName: "",
    lastName: "",
    gender: "man",
    location: "",
    date: "",
    time: "",
    officer: "test",
    statement: "",
  });

  // Functie om een nieuwe aangifte toe te voegen
  const handleAddReport = () => {
    const newEntry = {
      id: Date.now(),
      opnameDatum: new Date().toLocaleDateString(),
      aangever: `${newReport.firstName} ${newReport.lastName}`,
      status: "lopend",
      behandelaar: newReport.officer,
      ...newReport,
    };

    setReports([...reports, newEntry]);
    setNewReport({
      firstName: "",
      lastName: "",
      gender: "man",
      location: "",
      date: "",
      time: "",
      officer: "test",
      statement: "",
    });
    setShowAddModal(false);
  };

  // Functie om een aangifte te bekijken
  const handleViewReport = (report) => {
    setSelectedReport(report);
    setShowViewModal(true);
  };

  // Functie om de status van het rapport te wijzigen
  const handleStatusChange = (newStatus) => {
    setReports(
      reports.map((report) =>
        report.id === selectedReport.id
          ? { ...report, status: newStatus }
          : report
      )
    );
    setSelectedReport({ ...selectedReport, status: newStatus });
  };

  const handleDeleteReport = (reportId) => {
    const updatedReports = reports.filter((report) => report.id !== reportId);
    setReports(updatedReports);
  };

  return (
    <div className="flex flex-col p-6 bg-gray-900 rounded-xl animate-fade-in delay-3s">
      <div className="flex-1">
        <header>
          <div className="flex items-center justify-center p-4 mb-6 bg-blue-600 rounded-xl">
            <h1 className="font-bold text-white text-1xl sm:text-3xl">
              Justitie & Veiligheid
            </h1>
          </div>
        </header>

        <div className="flex items-center justify-center p-4 mb-6">
          <h1 className="text-xl font-bold text-white sm:text-3xl">
            Onderzoeksrapporten & Aangiftes
          </h1>
        </div>

        <div className="p-4 bg-gray-800 rounded-xl lg:hidden">
          <h1 className="mb-6 text-3xl font-bold text-center text-white">
            Je scherm is te klein voor deze gegevens te bekijken. Draai je
            scherm of bekijken via een groter scherm.
          </h1>
        </div>

        {/* Dashboard Table */}
        <div className="hidden p-4 bg-gray-800 rounded-xl lg:block">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold text-white">
              Lopende Onderzoeksrapporten & Aangiftes
            </h2>
            <button
              onClick={() => setShowAddModal(true)}
              className="px-4 py-2 text-white bg-blue-600 rounded">
              Rapport/Aangifte Aanmaken
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full text-white bg-gray-700 table-auto rounded-xl">
              <thead>
                <tr>
                  <th className="px-4 py-2">Volgnummer</th>
                  <th className="px-4 py-2">Opnamedatum</th>
                  <th className="px-4 py-2">Aangever</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2">Behandelaar</th>
                  <th className="px-4 py-2">Actie</th>
                </tr>
              </thead>
              <tbody>
                {reports.length > 0 ? (
                  reports.map((report, index) => (
                    <tr key={report.id} className="text-center">
                      <td className="px-4 py-2">{index + 1}</td>
                      <td className="px-4 py-2">{report.opnameDatum}</td>
                      <td className="px-4 py-2">{report.aangever}</td>
                      <td className="px-4 py-2">{report.status}</td>
                      <td className="px-4 py-2">{report.behandelaar}</td>
                      <td className="flex justify-around">
                        <button
                          onClick={() => handleViewReport(report)}
                          className="px-2 py-1 bg-green-600 rounded">
                          Bekijk
                        </button>
                        <button
                          onClick={() => handleDeleteReport(report.id)}
                          className="px-2 py-1 bg-red-600 rounded">
                          Verwijder
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="px-4 py-2 text-center">
                      Geen gegevens beschikbaar.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Modal voor het aanmaken van een aangifte */}
        {showAddModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md p-6 mx-4 text-white bg-gray-800 rounded-xl">
              <h2 className="mb-4 text-xl font-bold">
                Nieuwe Rapport/Aangifte
              </h2>
              <div className="mb-4">
                <label className="block mb-1">Aangever voornaam:</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-700 rounded"
                  value={newReport.firstName}
                  onChange={(e) =>
                    setNewReport({ ...newReport, firstName: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Aangever achternaam:</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-700 rounded"
                  value={newReport.lastName}
                  onChange={(e) =>
                    setNewReport({ ...newReport, lastName: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Aangever geslacht:</label>
                <select
                  className="w-full p-2 bg-gray-700 rounded"
                  value={newReport.gender}
                  onChange={(e) =>
                    setNewReport({ ...newReport, gender: e.target.value })
                  }>
                  <option value="man">Man</option>
                  <option value="vrouw">Vrouw</option>
                  <option value="overig">Overig</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block mb-1">Pleegplaats:</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-700 rounded"
                  value={newReport.location}
                  onChange={(e) =>
                    setNewReport({ ...newReport, location: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Pleegdatum:</label>
                <input
                  type="date"
                  className="w-full p-2 bg-gray-700 rounded"
                  value={newReport.date}
                  onChange={(e) =>
                    setNewReport({ ...newReport, date: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Pleegtijd:</label>
                <input
                  type="time"
                  className="w-full p-2 bg-gray-700 rounded"
                  value={newReport.time}
                  onChange={(e) =>
                    setNewReport({ ...newReport, time: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Verbalisant:</label>
                <input
                  type="text"
                  className="w-full p-2 bg-gray-700 rounded"
                  value={newReport.officer}
                  onChange={(e) =>
                    setNewReport({ ...newReport, officer: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Verklaring:</label>
                <textarea
                  className="w-full p-2 bg-gray-700 rounded"
                  value={newReport.statement}
                  onChange={(e) =>
                    setNewReport({ ...newReport, statement: e.target.value })
                  }
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setShowAddModal(false)}
                  className="px-4 py-2 bg-red-600 rounded">
                  Annuleren
                </button>
                <button
                  onClick={handleAddReport}
                  className="px-4 py-2 bg-blue-600 rounded">
                  Opslaan
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal voor het bekijken van een aangifte */}
        {showViewModal && selectedReport && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md p-6 mx-4 text-white bg-gray-800 rounded-xl">
              <h2 className="mb-4 text-xl font-bold">Aangifte Details</h2>
              <div className="mb-4">
                <label className="block mb-1">Aangever:</label>
                <p>{selectedReport.aangever}</p>
              </div>
              <div className="mb-4">
                <label className="block mb-1">Pleegplaats:</label>
                <p>{selectedReport.location}</p>
              </div>
              <div className="mb-4">
                <label className="block mb-1">Pleegdatum:</label>
                <p>{selectedReport.date}</p>
              </div>
              <div className="mb-4">
                <label className="block mb-1">Pleegtijd:</label>
                <p>{selectedReport.time}</p>
              </div>
              <div className="mb-4">
                <label className="block mb-1">Verbalisant:</label>
                <p>{selectedReport.officer}</p>
              </div>
              <div className="mb-4">
                <label className="block mb-1">Verklaring:</label>
                <p>{selectedReport.statement}</p>
              </div>
              {/* Status aanpassen */}
              <div className="mb-4">
                <label className="block mb-1">Status:</label>
                <select
                  className="w-full p-2 bg-gray-700 rounded"
                  value={selectedReport.status}
                  onChange={(e) => handleStatusChange(e.target.value)}>
                  <option value="lopend">Lopend</option>
                  <option value="in de wacht">In de wacht</option>
                  <option value="gesloten">Gesloten</option>
                </select>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setShowViewModal(false)}
                  className="px-4 py-2 bg-blue-600 rounded">
                  Sluiten
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JusAdmin;
