import React from "react";

const Huiszoek = () => {
  return (
    <div className="flex flex-col items-center justify-center flex-1 px-4 py-8 mx-auto sm:px-6 sm:py-12 animate-fade-in delay-3s">
      <h1 className="text-3xl font-bold ">Huiszoekingen</h1>
      <h1 className="text-4xl font-bold ">
        Under Construction - In ontwikkeling
      </h1>
      <p className="mt-4 text-lg ">
        This page is currently under construction. Please check back later.
      </p>

      <p className="mt-4 text-lg ">
        Aan deze pagina wordt momenteel gewerkt. Kom later nog eens terug.
      </p>
      <img
        src="/img/underconstruction.svg"
        alt="Under Construction"
        className="mt-8 h-[700px]"
      />
    </div>
  );
};

export default Huiszoek;
