import React, { useState } from "react";

const itemsForSale = [
  {
    id: 1,
    title: "Diamond Sword",
    mc: true,
    description: "A powerful sword made of diamond.",
    price: "€1000",
    image: "diamond_sword",
    category: "items",
  },
  {
    id: 2,
    title: "Enchanted Book",
    mc: true,
    description: "A book with powerful enchantments.",
    price: "€500",
    image: "book_enchanted",
    category: "items",
  },
  {
    id: 3,
    title: "Golden Apple",
    mc: true,
    description: "A rare and powerful apple.",
    price: "€300",
    image: "apple_golden",
    category: "items",
  },
  {
    id: 4,
    title: "Luxury House",
    mc: false,
    description: "A beautiful luxury house with a garden.",
    price: "€50000",
    image: "/img/house.png",
    category: "houses",
  },
  {
    id: 5,
    title: "Modern Apartment",
    mc: false,
    description: "A modern apartment in the city center.",
    price: "€30000",
    image: "/img/modernhouse.png",
    category: "apartments",
  },
  // Add more items as needed
];

const Marktplaats = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const filteredItems =
    selectedCategory === "all"
      ? itemsForSale
      : itemsForSale.filter((item) => item.category === selectedCategory);

  return (
    <div className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b] animate-fadeIn delay-3s">
      <h1 className="mb-6 text-3xl font-bold text-white">Marktplaats</h1>
      <div className="mb-6">
        <button
          onClick={() => setSelectedCategory("all")}
          className={`px-4 py-2 mr-2 font-medium rounded-lg ${
            selectedCategory === "all" ? "bg-yellow-500" : "bg-gray-600"
          }`}>
          Alles
        </button>
        <button
          onClick={() => setSelectedCategory("items")}
          className={`px-4 py-2 mr-2 font-medium rounded-lg ${
            selectedCategory === "items" ? "bg-yellow-500" : "bg-gray-600"
          }`}>
          Items
        </button>
        <button
          onClick={() => setSelectedCategory("houses")}
          className={`px-4 py-2 mr-2 font-medium rounded-lg ${
            selectedCategory === "houses" ? "bg-yellow-500" : "bg-gray-600"
          }`}>
          Huizen
        </button>
        <button
          onClick={() => setSelectedCategory("apartments")}
          className={`px-4 py-2 font-medium rounded-lg ${
            selectedCategory === "apartments" ? "bg-yellow-500" : "bg-gray-600"
          }`}>
          Appartementen
        </button>
      </div>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {filteredItems.map((item) => (
          <div key={item.id} className="p-6 bg-[#2c2c2c] rounded-lg">
            {item.mc ? (
              <img
                src={`https://novaskinpacks-cbc.kxcdn.com/default/assets/minecraft/textures/items/${item.image}.png`}
                alt={item.title}
                className="object-cover w-1/2 mb-4 rounded-lg aspect-square image-rendering-pixelated"
              />
            ) : (
              <img
                src={item.image}
                alt={item.title}
                className="object-cover w-full mb-4 rounded-lg image-rendering-pixelated"
              />
            )}
            <h2 className="mb-2 text-xl font-semibold text-yellow-500">
              {item.title}
            </h2>
            <p className="mb-2 text-white">{item.description}</p>
            <p className="text-lg font-bold text-white">{item.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marktplaats;
