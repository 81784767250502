import React, { useState } from "react";
import NavLinks from "./nav-links";
import { PowerIcon } from "@heroicons/react/24/solid";

const SideNav = ({ onSelect }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSelect = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      {/* Sidebar for larger screens */}
      <div className="fixed top-0 left-0 z-10 flex-col hidden w-64 h-full px-6 py-12 space-y-4 overflow-x-hidden overflow-y-scroll md:flex bg-custom-gradient xl:overflow-hidden">
        <div className="flex flex-col items-center w-full p-4 space-y-4 md:space-y-6">
          {/* Afbeelding */}
          <img
            src="https://mc-heads.net/Avatar/_JustMichael"
            alt="Webpaneel afbeelding"
            className="w-24 h-24 border-4 border-white rounded-full md:h-40 md:w-40"
          />
          <div className="pb-4 text-center border-b-2 md:pb-6">
            <p className="text-2xl font-bold rounded-full md:text-4xl bg-epurple">
              1
            </p>
            <p className="text-2xl font-bold md:text-4xl">_JustMichael</p>
            <p className="font-semibold text-md md:text-xl">€19.927,51</p>
          </div>
        </div>

        <NavLinks onSelect={onSelect} isCollapsed={false} />
        <form className="">
          <button className="flex justify-center items-center w-full h-[48px] gap-2 p-2 font-bold transition-transform duration-300 ease-in-out transform rounded-md md:p-3 md:px-3 hover:scale-105">
            <PowerIcon className="w-6" />
            Uitloggen
          </button>
        </form>
      </div>
      {/* Mobile Dropdown Menu */}
      <div className="md:hidden">
        <div className="block w-full px-4 py-4 bg-custom-gradient">
          <NavLinks
            onSelect={() => {
              onSelect(); // Sluit het menu na selectie
            }}
            isCollapsed={false}
          />
          <form className="mt-4">
            <button className="flex justify-center items-center w-full h-[48px] gap-2 p-2 font-bold transition-transform duration-300 ease-in-out transform rounded-md md:p-3 md:px-3 hover:scale-105">
              <PowerIcon className="w-6" />
              Uitloggen
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default SideNav;
