import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PaperClipIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import {
  HomeIcon,
  MapPinIcon,
  AcademicCapIcon,
  BriefcaseIcon,
  BookOpenIcon,
} from "@heroicons/react/16/solid";

const links = [
  { name: "Home", href: "/paneel", icon: HomeIcon },
  { name: "Overheid", href: "/paneel/overheid", icon: MapPinIcon },
  { name: "Marktplaats", href: "/paneel/marktplaats", icon: MapPinIcon },
  { name: "Bedrijven", href: "/paneel/bedrijven", icon: BriefcaseIcon },
  { name: "Opleidingen", href: "/paneel/opleidingen", icon: AcademicCapIcon },
  { name: "Aanvragen", href: "/paneel/aanvragen", icon: BookOpenIcon },
  { name: "Berichten", href: "/paneel/berichten", icon: PaperClipIcon },
  { name: "Mijn account", href: "/paneel/account", icon: UserCircleIcon },
];

const NavLinks = ({ onSelect, isCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleSelect = (href) => {
    setActiveLink(href);
    onSelect(href);
    navigate(href);
  };

  return (
    <div className="flex flex-col w-full space-y-4">
      {links.map((link) => {
        const LinkIcon = link.icon;
        const isActive = activeLink === link.href;

        return (
          <div key={link.name}>
            <button
              onClick={() => handleSelect(link.href)}
              className={clsx(
                "flex items-center justify-center w-full p-2 rounded-md transition-transform duration-300 ease-in-out transform hover:scale-105",
                {
                  "bg-epurple-light text-white": isActive, // Active link styling
                  "text-gray-200 hover:bg-epurple-light": !isActive, // Non-active link styling
                }
              )}>
              <LinkIcon className="w-6 h-6 mr-2" />
              {!isCollapsed && <p className="block lg:block">{link.name}</p>}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default NavLinks;
