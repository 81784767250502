import React from "react";
import { Link } from "react-router-dom";

const PanelLoginPage = () => {
  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-[#3B198D] via-[#4a1cb8] to-[#1b1b1b]">
        <div className="flex flex-col w-full h-auto max-w-2xl md:flex-row animate-fade-in delay-3s">
          {/* Formulier sectie */}
          <div className="w-full p-8 bg-[#f1f1f1] rounded-3xl md:rounded-r-none md:w-2/3 xl:rounded-l-3xl shadow-lg">
            <form className="space-y-6">
              <h1 className="text-3xl font-bold text-purple-700">ECLIPSEMC</h1>
              <small className="font-semibold text-purple-500">Inloggen</small>

              {/* Email invoerveld */}
              <div className="form-group">
                <label htmlFor="email" className="block text-sm text-gray-600">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Voer je email in"
                  className="w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              {/* Wachtwoord invoerveld */}
              <div className="form-group">
                <label
                  htmlFor="password"
                  className="block text-sm text-gray-600">
                  Wachtwoord
                </label>
                <input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Voer je wachtwoord in"
                  className="w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              {/* Remember me checkbox */}
              <div className="flex items-center">
                <input
                  id="remember_me"
                  type="checkbox"
                  className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  name="remember"
                />
                <label
                  htmlFor="remember_me"
                  className="ml-2 text-sm text-gray-900">
                  Onthoud mij
                </label>
              </div>

              {/* Wachtwoord vergeten en terug link */}
              <div className="flex items-center justify-between">
                <a
                  href="password.request"
                  className="text-sm font-semibold text-indigo-600 hover:underline">
                  Wachtwoord vergeten?
                </a>
                <Link
                  to="/"
                  className="px-4 py-2 text-xs font-medium text-white transition-colors bg-indigo-600 rounded hover:bg-indigo-700">
                  Terug
                </Link>
              </div>

              {/* Inloggen knop */}
              <div className="flex justify-end mt-auto">
                <button
                  type="submit"
                  className="block w-full py-2 mt-4 font-medium text-white transition-colors duration-300 ease-in-out bg-purple-700 shadow-lg hover:bg-purple-800 rounded-xl">
                  <Link to="/paneel">Inloggen</Link>
                </button>
              </div>
            </form>
          </div>

          {/* Informatie sectie */}
          <div className="items-center justify-center hidden w-full p-8 bg-gradient-to-b from-[#3B198D] to-[#4a1cb8] md:flex md:w-4/6 rounded-r-3xl">
            <div className="max-w-sm text-center">
              <h1 className="mb-2 text-3xl font-bold">Webpaneel</h1>
              <div className="flex justify-center pb-3">
                <img
                  src="/img/panel.svg"
                  alt="Webpaneel afbeelding"
                  className="h-32"
                />
              </div>
              <h2 className="mb-4 text-lg text-gray-300">
                Beheer je bedrijf, bekijk huizen, statistieken,
                overheidsadministratie en meer via het paneel.
              </h2>
              <p className="text-lg">Nog geen account? </p>
              <p className="text-gray-300">
                <Link
                  to="/register"
                  className="block w-full py-2 mt-12 font-medium text-gray-200 transition-colors duration-300 ease-in-out shadow-lg bg-epurple hover:bg-purple-800 rounded-xl">
                  Registreer nu
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <svg
        className="hidden md:block hero-waves"
        viewBox="0 24 150 28"
        preserveAspectRatio="none">
        <defs>
          <path
            id="wave-path"
            d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="wave1">
          <use
            xlinkHref="#wave-path"
            x="50"
            y="3"
            fill="rgba(255,255,255, 0.1)"
          />
        </g>
        <g className="wave2">
          <use
            xlinkHref="#wave-path"
            x="50"
            y="0"
            fill="rgba(255,255,255, 0.2)"
          />
        </g>
        <g className="wave3">
          <use
            xlinkHref="#wave-path"
            x="50"
            y="9"
            fill="rgba(255,255,255, 0.4)"
          />
        </g>
      </svg>
    </div>
  );
};

export default PanelLoginPage;
