import React, { useState } from "react";
import { IoClose } from "react-icons/io5";

const Popup = () => {
  const [isVisible, setIsVisible] = useState(true);

  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  if (!isVisible) return null;

  return (
    <div
      className={`fixed z-50 p-6 rounded-lg shadow-lg bg-epurple bottom-4 left-4 transition-transform duration-500 ease-in-out transform ${
        isVisible ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"
      }`}>
      <button
        aria-label="Close Popup"
        className="absolute text-white top-2 right-2"
        onClick={togglePopup}>
        <IoClose className="text-2xl" />
      </button>
      <h2 className="mb-2 text-2xl font-semibold text-white">
        Join onze server
      </h2>
      <p className="text-lg text-white">play.eclipsemc.com</p>
    </div>
  );
};

export default Popup;
