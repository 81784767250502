import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { HomeIcon, UserCircleIcon } from "@heroicons/react/16/solid";

// Basis navigatie links
const defaultLinks = [
  { name: "Home", href: "/overheid", icon: HomeIcon },
  { name: "Profiel", href: "/overheid/profiel", icon: UserCircleIcon },
];

const NavLinks = ({ onSelect, isCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [departmentLinks, setDepartmentLinks] = useState([]);

  useEffect(() => {
    if (location.state && location.state.links) {
      setDepartmentLinks(location.state.links);
    }
  }, [location.state]);

  const handleSelect = (href) => {
    setActiveLink(href);
    if (onSelect) onSelect(href);
    navigate(href);
  };

  // Combineer standaard links met de departement-specifieke links
  const combinedLinks = [...defaultLinks, ...departmentLinks];

  return (
    <div className="flex flex-col w-full space-y-4">
      {combinedLinks.map((link) => {
        const LinkIcon = link.icon;
        const isActive = activeLink === link.href;

        return (
          <div key={link.name}>
            <button
              onClick={() => handleSelect(link.href)}
              className={clsx(
                "flex items-center w-full gap-2 p-2 transition-transform duration-300 ease-in-out transform rounded-md hover:bg-gray-700 hover:scale-105",
                {
                  "bg-epurple-light text-white": isActive, // Active link styling
                  "hover:bg-gray-700": !isActive, // Non-active link styling
                }
              )}>
              <LinkIcon className="w-6 h-6 mr-2" />
              {!isCollapsed && <p>{link.name}</p>}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default NavLinks;
