import React from "react";
import Hero from "./Hero";
import Main from "./Main";
import Popup from "./Popup";

const Home = () => {
  return (
    <div className="relative bg-custom-gradient">
      <Hero />
      <Main />
      <Popup />
    </div>
  );
};

export default Home;
