import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PowerIcon, HomeIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const SideNav = ({ items = [], onSelect }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (href) => {
    navigate(href);
    if (onSelect) {
      onSelect(href);
    }
  };

  return (
    <div className="flex flex-col items-center w-full p-4 space-y-4">
      {/* Profiel Afbeelding en Info */}
      <img
        src="https://mc-heads.net/Avatar/_JustMichael"
        alt="Webpaneel afbeelding"
        className="w-24 h-24 border-4 border-blue-500 rounded-full"
      />
      <div className="pb-4 text-center border-b-2">
        <p className="text-2xl font-bold">_JustMichael</p>
        <p className="px-4 py-1 mt-2 text-sm font-semibold text-white bg-blue-500 rounded-full">
          Korpschef
        </p>
        <p className="px-4 py-1 mt-2 text-sm font-semibold text-white bg-red-500 rounded-full">
          Stafflid
        </p>
      </div>
      <div className="w-full space-y-4">
        {items.map((item, index) => (
          <div
            key={index}
            className={`flex items-center p-2 transition-colors duration-200 rounded-md cursor-pointer ${
              location.pathname === item.href
                ? "bg-epurple-light text-white"
                : "hover:bg-gray-700"
            }`}
            onClick={() => handleNavigation(item.href)}>
            {item.icon}
            <p className="ml-4 text-white">{item.text}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center w-full mt-4 space-y-4">
        <Link
          to="/overheid"
          className="flex items-center w-full gap-2 p-2 transition-transform duration-300 ease-in-out transform rounded-md hover:bg-red-600 hover:scale-105">
          <HomeIcon className="w-6" />
          <p className="ml-2">Portaal</p>
        </Link>
      </div>
      <div className="flex flex-col items-center w-full mt-4 space-y-4">
        <button className="flex items-center w-full gap-2 p-2 transition-transform duration-300 ease-in-out transform rounded-md hover:bg-red-600 hover:scale-105">
          <PowerIcon className="w-6" />
          <p className="ml-2">Uitloggen</p>
        </button>
      </div>
    </div>
  );
};

export default SideNav;
