import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { MdAttachEmail } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import {
  BsList,
  BsChatLeftTextFill,
  BsX,
  BsHouseDoorFill,
  BsBookFill,
  BsCalendarCheckFill,
  BsRewindBtnFill,
  BsEnvelopeFill,
} from "react-icons/bs";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <header className="bg-gradient-to-b from-[#3B198D] to-[#4a1cb8]">
      <div className="container flex items-center justify-between px-10 py-6 mx-auto md:px-20">
        <div className="flex items-center justify-center">
          <h1 className="text-3xl font-semibold text-center text-white md:text-left">
            EclipseMC
          </h1>
        </div>
        <div className="hidden space-x-8 font-medium lg:flex">
          <Link
            className={`text-lg nav-link ${isActive("/") ? "text-pink" : ""}`}
            to="/">
            Home
          </Link>
          <Link
            className={`text-lg nav-link ${
              isActive("/wiki") ? "text-pink" : ""
            }`}
            to="/wiki">
            Wiki
          </Link>
          <Link
            className={`text-lg nav-link ${
              isActive("/paneel") ? "text-pink" : ""
            }`}
            to="/login">
            Paneel
          </Link>
          <Link
            className={`text-lg nav-link ${
              isActive("/team") ? "text-pink" : ""
            }`}
            to="/team">
            Team
          </Link>
          <Link
            className={`text-lg nav-link ${
              isActive("/contact") ? "text-pink" : ""
            }`}
            to="/contact">
            Contact
          </Link>
        </div>
        <div className="lg:hidden">
          <button
            className="text-white focus:outline-none"
            onClick={toggleMenu}>
            {isMenuOpen ? <BsX size={30} /> : <BsList size={30} />}
          </button>
        </div>
      </div>
      <div
        className={`fixed top-0 bottom-0 right-0 w-full z-[9991] p-5 bg-[#1b1b1b] transition-transform duration-[0.7s] ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } lg:hidden`}>
        <div className="flex justify-end">
          <button
            className="text-xl text-white border-none cursor-pointer"
            onClick={toggleMenu}>
            <IoClose className="text-2xl" />
          </button>
        </div>

        <div className="flex items-center justify-center">
          <h1 className="p-20 text-4xl font-semibold text-white">EclipseMC</h1>
        </div>
        <nav className="flex justify-center py-4" id="navbar">
          <ul className="space-y-4 text-center">
            <li>
              <Link
                to="/"
                onClick={toggleMenu}
                className={`flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-epurple hover:border-epurple ${
                  isActive("/") ? "text-epurple" : ""
                }`}>
                <BsHouseDoorFill className="mr-2" /> Home
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/wiki"
                className={`flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-epurple hover:border-epurple ${
                  isActive("/wiki") ? "text-epurple" : ""
                }`}>
                <BsBookFill className="mr-2" /> Wiki
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/paneel"
                className={`flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-epurple hover:border-epurple ${
                  isActive("/paneel") ? "text-epurple" : ""
                }`}>
                <BsCalendarCheckFill className="mr-2" /> Paneel
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/team"
                className={`flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-epurple hover:border-epurple ${
                  isActive("/team") ? "text-epurple" : ""
                }`}>
                <BsRewindBtnFill className="mr-2" /> Team
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/over-ons"
                className={`flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-epurple hover:border-epurple ${
                  isActive("/over-ons") ? "text-epurple" : ""
                }`}>
                <BsEnvelopeFill className="mr-2" /> Over ons
              </Link>
            </li>
            <li>
              <Link
                onClick={toggleMenu}
                to="/contact"
                className={`flex items-center justify-center border-b-2 border-transparent md:text-lg hover:text-epurple hover:border-epurple ${
                  isActive("/contact") ? "text-epurple" : ""
                }`}>
                <BsChatLeftTextFill className="mr-2" />
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <h2 className="m-2 text-2xl font-bold text-center border-b-2 border-gray-200 text-epurple">
          Contact
        </h2>
        <ul className="flex flex-col gap-4 mb-5">
          <li className="flex items-center justify-center gap-4 text-lg">
            <span className="flex items-center justify-center w-12 h-12 rounded-full bg-epurple">
              <MdAttachEmail className="text-[var(--dark)]" />
            </span>
            <span className="text-sm">
              <small className="block text-sm font-medium text-white">
                U kunt ons bereiken via email:
              </small>
              info@eclipsemc.nl
            </span>
          </li>
        </ul>

        <div className="py-5 text-center text-xs text-[var(--black)]">
          <p className="no-underline bold">
            Copyright{" "}
            <strong>
              <span className="text-epurple">Flanders Visual Studio</span>
            </strong>{" "}
            All Rights Reserved.
          </p>
          <p>
            Designed by{" "}
            <a href="https://www.flandersvisualstudio.be">Michael</a> with love{" "}
            <FaHeart className="inline-block text-epurple" />
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
