import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import SideNav from "./side-nav";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex flex-col min-h-screen md:flex-row bg-gradient-to-b from-[#3B198D] via-[#4a1cb8] to-[#00022f]">
      {/* Mobile Header */}
      <div className="container flex items-center justify-between px-10 py-6 mx-auto md:px-20 md:hidden">
        <div className="flex items-center space-x-4">
          <p className="text-xl font-bold text-white">Wiki navigatie</p>
        </div>
        <button onClick={toggleMenu} className="text-white">
          {isMenuOpen ? (
            <XMarkIcon className="w-6 h-6" />
          ) : (
            <Bars3Icon className="w-6 h-6" />
          )}
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } md:flex flex-col animate-fadeIn md:px-6 w-full md:w-64`}>
        <SideNav
          onSelect={() => setIsMenuOpen(false)} // Sluit menu na selectie
        />
      </div>

      {/* Main content */}
      <main className="container flex items-center justify-between px-10 py-6 mx-auto md:px-10">
        <Outlet />
      </main>
    </div>
  );
};

export default Dashboard;
