import React, { useState } from "react";

const ZoekDossier = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [reports, setReports] = useState([
    {
      id: 1,
      opnameDatum: "14-10-2024",
      aangever: "Jan Jansen",
      status: "Lopend",
      behandelaar: "Piet Pietersen",
      verklaring: "Diefstal bij supermarkt",
    },
    {
      id: 2,
      opnameDatum: "13-10-2024",
      aangever: "Klaas Klaassen",
      status: "Gesloten",
      behandelaar: "Marie Mare",
      verklaring: "Fietsendiefstal",
    },
    {
      id: 3,
      opnameDatum: "12-10-2024",
      aangever: "Lisa Li",
      status: "Wachtend",
      behandelaar: "Jan Janssen",
      verklaring: "Autoinbraak",
    },
  ]);

  // Filter de rapporten op basis van zoekopdracht
  const filteredReports = reports.filter(
    (report) =>
      report.aangever.toLowerCase().includes(searchQuery.toLowerCase()) ||
      report.status.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Functie om een dossier te openen
  const openReport = (report) => {
    setSelectedReport(report);
    setShowModal(true);
  };

  return (
    <div className="flex flex-col p-6 bg-gray-900 rounded-xl animate-fade-in delay-3s">
      <div className="flex-1">
        <header>
          <div className="flex items-center justify-center p-4 mb-6 bg-blue-600 rounded-xl">
            <h1 className="font-bold text-white text-1xl sm:text-3xl">
              Justitie & Veiligheid
            </h1>
          </div>
        </header>
        <div className="flex items-center justify-center p-4 mb-6">
          <h1 className="text-xl font-bold text-white sm:text-3xl">
            Zoek dossier
          </h1>
        </div>

        <div className="p-4 bg-gray-800 rounded-xl lg:hidden">
          <h1 className="mb-6 text-3xl font-bold text-center text-white">
            Je scherm is te klein voor deze gegevens te bekijken. Draai je
            scherm of bekijken via een groter scherm.
          </h1>
        </div>

        <div className="hidden p-4 bg-gray-800 rounded-xl lg:block">
          <div className="flex justify-center mb-6">
            <input
              type="text"
              placeholder="Zoek op aangever of status..."
              className="w-full max-w-md p-3 text-white bg-gray-700 rounded-xl focus:outline-none"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Tabel voor de gefilterde dossiers */}
          <div className="overflow-auto">
            <table className="min-w-full text-white bg-gray-700 table-auto rounded-xl">
              <thead>
                <tr className="bg-gray-600">
                  <th className="px-4 py-2">Volgnummer</th>
                  <th className="px-4 py-2">Opnamedatum</th>
                  <th className="px-4 py-2">Aangever</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2">Behandelaar</th>
                  <th className="px-4 py-2">Actie</th>
                </tr>
              </thead>
              <tbody>
                {filteredReports.length > 0 ? (
                  filteredReports.map((report, index) => (
                    <tr key={report.id} className="text-center">
                      <td className="px-4 py-2">{index + 1}</td>
                      <td className="px-4 py-2">{report.opnameDatum}</td>
                      <td className="px-4 py-2">{report.aangever}</td>
                      <td className="px-4 py-2">
                        <span
                          className={`px-2 py-1 rounded ${
                            report.status === "Lopend"
                              ? "bg-green-600"
                              : report.status === "Gesloten"
                              ? "bg-red-600"
                              : "bg-yellow-500"
                          }`}>
                          {report.status}
                        </span>
                      </td>
                      <td className="px-4 py-2">{report.behandelaar}</td>
                      <td className="px-4 py-2">
                        <button
                          onClick={() => openReport(report)}
                          className="px-2 py-1 bg-blue-600 rounded">
                          Bekijk
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="px-4 py-2 text-center text-gray-400">
                      Geen dossiers gevonden.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Modal voor het bekijken van een dossier */}
          {showModal && selectedReport && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="p-6 text-white bg-gray-800 w-96 rounded-xl">
                <h2 className="mb-4 text-xl font-bold">Dossier Details</h2>
                <div className="mb-4">
                  <label className="block mb-1">Aangever:</label>
                  <p>{selectedReport.aangever}</p>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Opnamedatum:</label>
                  <p>{selectedReport.opnameDatum}</p>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Status:</label>
                  <p>{selectedReport.status}</p>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Behandelaar:</label>
                  <p>{selectedReport.behandelaar}</p>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Verklaring:</label>
                  <p>{selectedReport.verklaring}</p>
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => setShowModal(false)}
                    className="px-4 py-2 bg-blue-600 rounded">
                    Sluiten
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZoekDossier;
