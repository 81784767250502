import React, { useState } from "react";

const Account = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleUpdateAccount = () => {
    setLoading(true);
    // Simulate an API call
    setTimeout(() => {
      setLoading(false);
      setMessage("Account updated successfully!");
    }, 2000);
  };

  const handleUpdatePassword = () => {
    if (password !== confirmPassword) {
      setMessage("Passwords do not match!");
      return;
    }
    setLoading(true);
    // Simulate an API call
    setTimeout(() => {
      setLoading(false);
      setMessage("Password updated successfully!");
    }, 2000);
  };

  return (
    <div className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b] animate-fadeIn delay-3s">
      <h1 className="mb-6 text-3xl font-bold text-white">Mijn account</h1>
      <div className="fixed top-0 right-0 p-4">
        {message && (
          <div className="p-4 mt-4 text-white bg-green-500 rounded-lg">
            {message}
          </div>
        )}
      </div>
      <div className="space-y-8">
        {/* User Information Section */}
        <div className="p-6 bg-[#2c2c2c] rounded-lg">
          <h2 className="mb-4 text-2xl font-semibold">Gebruikersinformatie</h2>
          <p className="text-gray-300 text-md">Naam: Michael</p>
          <p className="text-gray-300 text-md">Minecraftnaam: _JustMichael</p>
          <p className="text-gray-300 text-md">Email: michael@example.com</p>
          <p className="text-gray-300 text-md">Lid sinds: 1 januari 2023</p>
          <h2 className="pt-2 text-xl font-semibold">Permissies en rollen</h2>
          <div className="flex gap-2 mt-6">
            <p className="px-4 font-semibold bg-gray-600 text-md rounded-xl">
              ONDERNEMER
            </p>
            <p className="px-4 font-semibold bg-red-600 text-md rounded-xl">
              STAFF
            </p>
            <p className="px-4 font-semibold text-md bg-cyan-600 rounded-xl">
              GEMEENTE
            </p>
            <p className="px-4 font-semibold text-md bg-cyan-600 rounded-xl">
              BURGEMEESTER
            </p>
          </div>
        </div>

        {/* Account Settings Section */}
        <div className="p-6 bg-[#2c2c2c] rounded-lg">
          <h2 className="mb-4 text-2xl font-semibold">Accountinstellingen</h2>
          <div className="space-y-4">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-400">
                Gebruikersnaam wijzigen
              </label>
              <input
                type="text"
                placeholder="Nieuwe gebruikersnaam"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-4 py-2 text-gray-700 bg-gray-200 rounded-lg focus:outline-none"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-400">
                Email wijzigen
              </label>
              <input
                type="email"
                placeholder="Nieuw emailadres"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 text-gray-700 bg-gray-200 rounded-lg focus:outline-none"
              />
            </div>
            <button
              onClick={handleUpdateAccount}
              className="px-4 py-2 font-medium rounded-lg bg-epurple hover:bg-epurple-light"
              disabled={loading}>
              {loading ? "Bijwerken..." : "Account bijwerken"}
            </button>
          </div>
        </div>

        {/* Password Settings Section */}
        <div className="p-6 bg-[#2c2c2c] rounded-lg">
          <h2 className="mb-4 text-2xl font-semibold">Wachtwoord wijzigen</h2>
          <div className="space-y-4">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-400">
                Nieuw wachtwoord
              </label>
              <input
                type="password"
                placeholder="Nieuw wachtwoord"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 text-gray-700 bg-gray-200 rounded-lg focus:outline-none"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-400">
                Bevestig wachtwoord
              </label>
              <input
                type="password"
                placeholder="Bevestig nieuw wachtwoord"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-2 text-gray-700 bg-gray-200 rounded-lg focus:outline-none"
              />
            </div>
            <button
              onClick={handleUpdatePassword}
              className="px-4 py-2 font-medium rounded-lg bg-epurple hover:bg-epurple-light"
              disabled={loading}>
              {loading ? "Bijwerken..." : "Wachtwoord bijwerken"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
