import React from "react";

const JusAdmin = () => {
  const employees = [
    {
      name: "_JustMichael",
      role: "Korpschef",
      avatar: "https://mc-heads.net/Avatar/_JustMichael",
    },
    {
      name: "Notch",
      role: "Hoofdagent",
      avatar: "https://mc-heads.net/Avatar/notch",
    },
    {
      name: "Steve",
      role: "Agent",
      avatar: "https://mc-heads.net/Avatar/steve",
    },
    {
      name: "Alex",
      role: "Agent",
      avatar: "https://mc-heads.net/Avatar/alex",
    },
  ];

  const recentActivities = [
    {
      title: "Nieuwe aangifte",
      description: "Er is een nieuwe aangifte binnengekomen.",
      date: "Vandaag",
    },
    {
      title: "Politiereglement update",
      description: "Het politiereglement is bijgewerkt.",
      date: "Gisteren",
    },
    {
      title: "Nieuwe medewerker",
      description: "Welkom aan onze nieuwe agent, Alex.",
      date: "2 dagen geleden",
    },
  ];

  const importantNotices = [
    {
      title: "Belangrijke mededeling",
      description: "Er is een belangrijke mededeling voor alle medewerkers.",
    },
    {
      title: "Veiligheidsupdate",
      description: "Er is een nieuwe veiligheidsupdate beschikbaar.",
    },
  ];

  return (
    <div className="flex p-6 bg-gray-900 rounded-xl animate-fade-in delay-3s">
      <div className="flex-1">
        <header>
          <div className="flex items-center justify-center p-4 mb-6 bg-blue-600 rounded-xl">
            <h1 className="font-bold text-white text-1xl sm:text-3xl">
              Justitie & Veiligheid
            </h1>
          </div>
        </header>

        <div className="flex flex-col items-center justify-center p-6 mb-6 bg-gray-800 shadow-lg rounded-xl">
          <h1 className="mb-2 text-xl font-bold text-center text-white sm:text-2xl">
            Welkom, user, in het MEOS systeem van departement Justitie &
            Veiligheid.
          </h1>
          <p className="text-center text-white">
            Dit systeem is uitsluitend voor geautoriseerd gebruik. Misbruik van
            dit systeem kan leiden tot ontslag en/of strafvervolging.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* Recent Activities */}
          <div className="p-4 bg-gray-800 rounded-xl">
            <h2 className="mb-4 text-xl font-bold text-white">
              Recente Activiteiten
            </h2>
            {recentActivities.map((activity, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-lg font-semibold text-blue-400">
                  {activity.title}
                </h3>
                <p className="text-gray-300">{activity.description}</p>
                <p className="text-sm text-gray-500">{activity.date}</p>
              </div>
            ))}
          </div>

          {/* Important Notices */}
          <div className="p-4 bg-gray-800 rounded-xl">
            <h2 className="mb-4 text-xl font-bold text-white">
              Belangrijke Mededelingen
            </h2>
            {importantNotices.map((notice, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-lg font-semibold text-blue-400">
                  {notice.title}
                </h3>
                <p className="text-gray-300">{notice.description}</p>
              </div>
            ))}
          </div>

          {/* Employees */}
          <div className="p-4 bg-gray-800 rounded-xl">
            <h2 className="mb-4 text-xl font-bold text-white">Medewerkers</h2>
            {employees.map((employee, index) => (
              <div key={index} className="flex items-center gap-4 mb-4">
                <img
                  src={employee.avatar}
                  alt={`${employee.name} avatar`}
                  className="w-10 h-10 rounded-full md:w-15 md:h-15"
                />
                <div>
                  <h3 className="text-lg font-semibold text-blue-400">
                    {employee.name}
                  </h3>
                  <p className="text-gray-300">{employee.role}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JusAdmin;
