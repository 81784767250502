import React, { useState } from "react";
import Police from "./gov/police";
import Interior from "./gov/interior";
import Economy from "./gov/economy";
import Finance from "./gov/finance";
import Infra from "./gov/infra";
import { Link } from "react-router-dom";

const Overheid = () => {
  const [activeTab, setActiveTab] = useState("justitie");

  const renderContent = () => {
    switch (activeTab) {
      case "justitie":
        return <Police />;
      case "binnenlandsbestuur":
        return <Interior />;
      case "economie":
        return <Economy />;
      case "financien":
        return <Finance />;
      case "infrastructuur":
        return <Infra />;
      default:
        return <p>Selecteer een overheids departement</p>;
    }
  };

  return (
    <div className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b] animate-fadeIn delay-3s">
      <h1 className="mb-6 text-3xl font-bold text-white">Overheidsdiensten</h1>
      <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        <button
          onClick={() => setActiveTab("justitie")}
          className={`px-4 py-2 font-medium rounded-lg duration-300 hover:scale-105 ${
            activeTab === "justitie"
              ? "bg-eyellow text-[#1b1b1b]"
              : "bg-[#2b2b2b] shadow-inner"
          }`}>
          Justitie & Veiligheid
        </button>
        <button
          onClick={() => setActiveTab("binnenlandsbestuur")}
          className={`px-4 py-2 font-medium rounded-lg duration-300 hover:scale-105 ${
            activeTab === "binnenlandsbestuur"
              ? "bg-eyellow text-[#1b1b1b]"
              : "bg-[#2b2b2b] shadow-inner"
          }`}>
          Binnenlands Bestuur
        </button>
        <button
          onClick={() => setActiveTab("economie")}
          className={`px-4 py-2 font-medium rounded-lg duration-300 hover:scale-105 ${
            activeTab === "economie"
              ? "bg-eyellow text-[#1b1b1b]"
              : "bg-[#2b2b2b] shadow-inner"
          }`}>
          Economische zaken
        </button>
        <button
          onClick={() => setActiveTab("financien")}
          className={`px-4 py-2 font-medium rounded-lg duration-300 hover:scale-105 ${
            activeTab === "financien"
              ? "bg-eyellow text-[#1b1b1b]"
              : "bg-[#2b2b2b] shadow-inner"
          }`}>
          Financiën
        </button>
        <button
          onClick={() => setActiveTab("infrastructuur")}
          className={`px-4 py-2 font-medium rounded-lg duration-300 hover:scale-105 ${
            activeTab === "infrastructuur"
              ? "bg-eyellow text-[#1b1b1b]"
              : "bg-[#2b2b2b] shadow-inner"
          }`}>
          Infrastructuur
        </button>
      </div>

      <div className="flex items-center justify-center w-full p-2">
        <Link
          to="/overheid"
          className="px-4 py-2 font-medium text-white bg-red-700 rounded-lg hover:bg-red-900">
          Toegang tot overheidsportaal
        </Link>
      </div>

      <div>{renderContent()}</div>
    </div>
  );
};

export default Overheid;
