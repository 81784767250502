import React from "react";

const teamMembers = [
  {
    role: "Developer",
    name: "Jan",
    description:
      "Hoofd Developer van de server. Jan zorgt ervoor dat alles soepel verloopt.",
  },
  {
    role: "Webdeveloper",
    name: "Michael",
    description:
      "Michael is verantwoordelijk voor de ontwikkeling van de website en het paneel zowel front- en backend.",
  },
  {
    role: "Ontwikkelaar",
    name: "Tim",
    description:
      "Tim is verantwoordelijk voor de ontwikkeling van de custom plugins en features.",
  },
];

const Contributors = () => {
  return (
    <div className="relative bg-gradient-to-b from-[#3B198D] via-[#4a1cb8] to-[#00022f]">
      <div className="relative z-20 flex flex-col items-center px-5 py-12 mx-auto lg:py-20">
        <div className="text-center">
          <h1 className="mb-6 text-3xl font-bold">Contributors</h1>
          <p>
            We bedanken alle mensen die geholpen hebben voor het succes van onze
            server.
          </p>

          <div className="mb-12">
            {teamMembers.map((member, index) => (
              <div key={index} className="mb-6">
                <h2 className="text-2xl font-semibold">
                  {member.role} - {member.name}
                </h2>
                <p>{member.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="custom-shape-divider-bottom-1727880601">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none">
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"></path>
        </svg>
      </div>
    </div>
  );
};

export default Contributors;
