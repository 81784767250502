import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SideNav from "./side-nav";
import {
  HomeIcon,
  ShieldCheckIcon,
  FolderIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  UserIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

const DepartmentDashboard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();
  const department = location.pathname.split("/department/")[1]?.split("/")[0];

  const sidebarItems = {
    jus: [
      {
        icon: <HomeIcon className="w-6 h-6 text-white" />,
        text: "Home",
        href: "/overheid/department/jus",
      },
      {
        icon: <ShieldCheckIcon className="w-6 h-6 text-white" />,
        text: "Rapport",
        href: "/overheid/department/jus/report",
      },
      {
        icon: <FolderIcon className="w-6 h-6 text-white" />,
        text: "Zoek dossier",
        href: "/overheid/department/jus/zoek-dossier",
      },
      {
        icon: <MagnifyingGlassIcon className="w-6 h-6 text-white" />,
        text: "Huiszoekingen",
        href: "/overheid/department/jus/huiszoekingen",
      },
      {
        icon: <UserIcon className="w-6 h-6 text-white" />,
        text: "Collega's",
        href: "/overheid/department/jus/collegas",
      },
      {
        icon: <PencilSquareIcon className="w-6 h-6 text-white" />,
        text: "Databanken",
        href: "/overheid/department/jus/databanken",
      },
      {
        icon: <FolderIcon className="w-6 h-6 text-white" />,
        text: "Intranet",
        href: "/overheid/department/jus/intranet",
      },
    ],

    // Add more departments as needed
  };

  const items = sidebarItems[department] || [];

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 md:flex-row">
      {/* Mobile Header */}
      <div className="flex items-center justify-between px-4 py-4 bg-gray-900 md:hidden">
        <div className="flex items-center space-x-4">
          <p className="text-xl font-bold text-white">Navigatie</p>
        </div>
        <button onClick={toggleMenu} className="text-white">
          {isMenuOpen ? (
            <XMarkIcon className="w-6 h-6" />
          ) : (
            <Bars3Icon className="w-6 h-6" />
          )}
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed inset-0 z-10 flex-col w-64 h-full px-6 py-12 space-y-4 overflow-auto text-white bg-gradient-to-b from-gray-800 to-gray-900 transition-transform duration-300 ease-in-out transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } md:relative md:translate-x-0 md:flex`}>
        <SideNav
          items={items}
          department={department}
          onSelect={() => setIsMenuOpen(false)}
        />
      </div>

      {/* Main content */}
      <main className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b] w-full">
        <Outlet />
      </main>
    </div>
  );
};

export default DepartmentDashboard;
