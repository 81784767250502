import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import SideNav from "./side-nav";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Outlet } from "react-router-dom";
import AdminHome from "../../../../pages/panel/pages/gov/admin/Portaal";

const Dashboard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-800 md:flex-row">
      {/* Mobile Header */}
      <div className="flex items-center justify-between px-4 py-4 md:hidden">
        <div className="flex items-center space-x-4">
          <p className="text-xl font-bold text-white">
            {" "}
            EclipseMC - Overheidsportaal
          </p>
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } md:flex flex-col animate-fadeIn md:px-6 w-full md:w-64`}>
        <SideNav
          onSelect={() => setIsMenuOpen(false)} // Sluit menu na selectie
        />
      </div>

      {/* Main content */}
      <main className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b] animate-fadeIn delay-3s w-full">
        <AdminHome />
      </main>
    </div>
  );
};

export default Dashboard;
