import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import SideNav from "../../ui/wiki/side-nav";

// Dummy data voor de recent bijgewerkte artikelen
const updatedArticles = [
  {
    title: "Lorem Ipsum",
    path: "/wiki/#",
    image: "https://via.placeholder.com/150",
    description: "lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Lorem Ipsum",
    path: "/wiki/#",
    image: "https://via.placeholder.com/150",
    description: "lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Lorem Ipsum",
    path: "/wiki/#",
    image: "https://via.placeholder.com/150",
    description: "lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Lorem Ipsum",
    path: "/wiki/#",
    image: "https://via.placeholder.com/150",
    description: "lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

const ArticleCard = ({ article }) => (
  <div className="flex flex-col p-4 transition-transform duration-300 ease-in-out transform bg-gray-800 rounded-lg shadow-lg hover:scale-105">
    <div className="mb-4">
      <img
        src={article.image}
        alt={article.title}
        className="object-cover w-full h-32 mb-4 rounded-lg"
        loading="lazy"
      />
      <h4 className="mb-2 text-lg font-semibold text-white">{article.title}</h4>
      <p className="text-sm text-gray-400">{article.description}</p>
    </div>
    <div className="flex justify-end mt-auto">
      <a
        href={article.path}
        className="block w-full py-2 mt-4 font-medium text-center text-white transition-colors duration-300 ease-in-out bg-purple-700 shadow-lg hover:bg-purple-800 rounded-xl"
        aria-label={`Lees meer over ${article.title}`}>
        Lees meer!
      </a>
    </div>
  </div>
);

const CategoryCard = ({ title, description, path }) => (
  <div className="flex flex-col p-6 transition-transform duration-300 ease-in-out transform bg-gray-800 rounded-lg shadow-lg hover:scale-105">
    <h4 className="mb-3 text-lg font-semibold text-white">{title}</h4>
    <p className="text-gray-400">{description}</p>
    <div className="flex justify-end mt-auto">
      <a
        href={path}
        className="block w-full py-2 mt-4 font-medium text-center text-white transition-colors duration-300 ease-in-out bg-purple-700 shadow-lg hover:bg-purple-800 rounded-xl"
        aria-label={`Lees meer over ${title}`}>
        Lees meer!
      </a>
    </div>
  </div>
);

const WikiMainPage = () => {
  return (
    <div>
      <div className="flex flex-col min-h-screen md:flex-row">
        <main className="flex-1 animate-fade-in delay-3s">
          {/* Page Title */}
          <div className="items-center block text-center sm:justify-between sm:flex">
            <h2 className="py-2 text-3xl font-semibold text-white">Home</h2>
            {/* Search Bar */}
            <div className="relative w-64">
              <label htmlFor="search" className="sr-only">
                Zoeken
              </label>
              <input
                type="text"
                id="search"
                className="peer sm:block justify-center flex w-full text-epurple rounded-md border border-gray-200 py-[7px] pl-10 text-sm outline-2 placeholder:text-gray-500"
                placeholder="Zoeken..."
              />
              <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
            </div>
          </div>
          {/* Recent Updated Articles Section */}
          <section>
            <h3 className="mb-6 text-xl font-semibold text-white">
              Recent geupdate artikelen
            </h3>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {updatedArticles.map((article, index) => (
                <ArticleCard key={index} article={article} />
              ))}
            </div>
          </section>
          <section className="p-6 mt-12 shadow-2xl bg-epurple rounded-2xl">
            <h3 className="mb-4 text-xl font-semibold">Aankomende updates</h3>
            <p className="text-gray-300">
              Er zijn momenteel geen aankomende updates gepland. Houd deze
              pagina in de gaten voor meer informatie.
            </p>
          </section>
          <section className="mt-12">
            <h3 className="mb-6 text-xl font-semibold text-white">
              Aanbevolen categorieën
            </h3>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <CategoryCard
                title="Beginner Handleidingen"
                description="Uitgebreide gids om je te helpen aan de slag te gaan met EclipseMC."
                path="/wiki"
              />
              <CategoryCard
                title="Gevorderde technieken"
                description="Leer geavanceerde technieken en strategieën om het spel onder de knie te krijgen."
                path="/wiki"
              />
              <CategoryCard
                title="Uitgelicht door de community"
                description="Spotlights op community-evenementen, topspelers en nog veel meer."
                path="/wiki"
              />
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default WikiMainPage;
