import React from "react";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterSection = () => {
  return (
    <footer className="bg-gradient-to-b from-[#00022f]  to-[#3B198D] text-white py-10">
      <div className="container px-5 mx-auto">
        <div className="flex flex-wrap justify-around md:justify-between">
          <div className="flex-[1_1_200px] flex justify-center items-center mb-5 md:mb-0">
            <a href="/">
              <img
                src="/img/logo-t.png"
                className="w-[150px] h-auto md:w-[200px]"
                alt="EclipseMC Logo"
              />
            </a>
          </div>
          <div className="flex-[1_1_200px] m-5 text-center md:text-left">
            <h3 className="mx-0 my-[5px] pb-[5px] font-semibold border-b-white border-b border-solid last:border-b-none">
              ECLIPSEMC
            </h3>
            <ul className="p-0 list-none">
              <li className="transition-transform duration-300 text-[#FF9800] ease-in-out transform hover:scale-105">
                Jouw favoriete Minecraft server met exclusieve gamemodes en een
                sterke community.
              </li>
            </ul>
          </div>

          <div className="flex-[1_1_150px] m-5 text-center md:text-left">
            <h3 className="mx-0 my-[5px] pb-[5px] font-semibold border-b-white border-b border-solid last:border-b-none">
              NAVIGATIE
            </h3>
            <ul className="text-[#FF9800]">
              <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                <a href="/">Home</a>
              </li>
              <Link to="/wiki">
                <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                  Wiki
                </li>
              </Link>
              <Link to="/login">
                <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                  Paneel
                </li>
              </Link>
              <Link to="/team">
                <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                  Team
                </li>
              </Link>

              <Link to="/contributors">
                <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                  Contributors
                </li>
              </Link>
            </ul>
          </div>

          <div className="flex-[1_1_150px] m-5 text-center md:text-left">
            <h3 className="mx-0 my-[5px] pb-[5px] font-semibold border-b-white border-b border-solid last:border-b-none">
              CONTACT
            </h3>
            <ul className="text-[#FF9800]">
              <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                support@eclipsemc.com
              </li>
              <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                Discord.gg/eclipsemc
              </li>
            </ul>
          </div>

          <div className="flex-[1_1_150px] m-5 text-center md:text-left">
            <h3 className="mx-0 my-[5px] pb-[5px] font-semibold border-b-white border-b border-solid last:border-b-none">
              VOLG ONS
            </h3>
            <ul className="text-[#FF9800]">
              <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                <a href="/">Instagram</a>
              </li>
              <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                <a href="/">Facebook</a>
              </li>
              <li className="transition-transform duration-300 ease-in-out transform hover:scale-105">
                <a href="/">X / Twitter</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="pt-5 text-sm text-center">
          <p className="text-[#FF9800]">
            © 2024 EclipseMC. Alle rechten voorbehouden.
          </p>
          <p>
            <a
              href="https://www.flandersvisualstudio.be"
              className="transition-colors duration-300 ease-in-out hover:text-[#ffb400]">
              Designed by Michael with love{" "}
              <FaHeart className="inline-block text-red-500 animate-pulse" />
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
