import React, { useState } from "react";
import NavLinks from "../wiki/nav-links";

const SideNav = ({ onSelect }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSelect = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <div className="flex flex-col h-full border-gray-700 animate-fadeIn xl:overflow-hidden">
        <div className="flex flex-col items-center w-full p-4 space-y-4 md:space-y-6">
          {/* Afbeelding */}
          <div className="w-full pb-4 text-center border-b-2 md:pb-6">
            <p className="text-2xl font-bold md:text-4xl">Wiki</p>
          </div>
        </div>

        <div className="flex-col hidden p-4 space-y-4 md:space-y-6 md:flex">
          <NavLinks onSelect={handleSelect} isCollapsed={false} />
        </div>

        <div className="md:hidden">
          <div className="block w-full px-4 py-4">
            <NavLinks
              onSelect={() => {
                handleSelect(); // Sluit het menu na selectie
              }}
              isCollapsed={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
