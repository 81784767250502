import React from "react";
import {
  CurrencyEuroIcon,
  PencilIcon,
  UserGroupIcon,
} from "@heroicons/react/16/solid";
import { Link } from "react-router-dom";

const Bedrijven = () => {
  return (
    <div className="flex-1 px-4 py-8 mx-auto sm:px-6 sm:py-12">
      <h1 className="mb-6 text-2xl font-bold text-white sm:text-3xl">
        Bedrijven
      </h1>
      <p className="p-5 text-sm shadow-xl sm:text-base bg-epurple rounded-xl">
        Als je een bedrijf wilt beginnen heb je hier een opleiding voor nodig.
        De opleiding om een bedrijf te starten begint vanaf "Bedrijfseconomie".
        Heb je een bedrijf zal deze hier onder weergegeven worden.
      </p>
      <div className="grid grid-cols-1 gap-6 p-5 sm:grid-cols-2 xl:grid-cols-4">
        <div className="p-6 text-center rounded-lg shadow-md bg-custom-gradient">
          <div className="h-32 mb-4 bg-teal-300 rounded-lg"></div>
          <div className="flex flex-row gap-3 p-2 text-gray-200 sm:gap-5">
            <div className="flex items-center justify-center h-8 px-3 bg-white rounded-full bg-opacity-20 sm:h-10 sm:px-4">
              <p className="text-sm">Memmet Store</p>
            </div>
            <div className="flex items-center justify-center h-8 px-3 bg-white rounded-full bg-opacity-20 sm:h-10 sm:px-4">
              <CurrencyEuroIcon className="w-5 h-5 sm:w-6 sm:h-6" />
              11389
            </div>
            <div className="flex items-center justify-center w-8 h-8 bg-white rounded-full bg-opacity-20 hover:scale-125 sm:w-10 sm:h-10">
              <Link to="#">
                <PencilIcon className="w-5 h-5 sm:w-6 sm:h-6" />
              </Link>
            </div>
          </div>
        </div>
        <div className="p-6 text-center rounded-lg shadow-md bg-custom-gradient">
          <div className="h-32 mb-4 bg-teal-300 rounded-lg"></div>
          <div className="flex flex-row gap-3 p-2 text-gray-200 sm:gap-5">
            <div className="flex items-center justify-center h-8 px-3 bg-white rounded-full bg-opacity-20 sm:h-10 sm:px-4">
              <p className="text-sm">Memmet Store</p>
            </div>
            <div className="flex items-center justify-center h-8 px-3 bg-white rounded-full bg-opacity-20 sm:h-10 sm:px-4">
              <CurrencyEuroIcon className="w-5 h-5 sm:w-6 sm:h-6" />
              11389
            </div>
            <div className="flex items-center justify-center w-8 h-8 bg-white rounded-full bg-opacity-20 hover:scale-125 sm:w-10 sm:h-10">
              <Link to="#">
                <PencilIcon className="w-5 h-5 sm:w-6 sm:h-6" />
              </Link>
            </div>
          </div>
        </div>
        <div className="p-6 text-center rounded-lg shadow-md bg-custom-gradient">
          <div className="h-32 mb-4 bg-teal-300 rounded-lg"></div>
          <div className="flex flex-row gap-3 p-2 text-gray-200 sm:gap-5">
            <div className="flex items-center justify-center h-8 px-3 bg-white rounded-full bg-opacity-20 sm:h-10 sm:px-4">
              <p className="text-sm">Memmet Store</p>
            </div>
            <div className="flex items-center justify-center h-8 px-3 bg-white rounded-full bg-opacity-20 sm:h-10 sm:px-4">
              <CurrencyEuroIcon className="w-5 h-5 sm:w-6 sm:h-6" />
              11389
            </div>
            <div className="flex items-center justify-center w-8 h-8 bg-white rounded-full bg-opacity-20 hover:scale-125 sm:w-10 sm:h-10">
              <Link to="#">
                <PencilIcon className="w-5 h-5 sm:w-6 sm:h-6" />
              </Link>
            </div>
          </div>
        </div>
        <div className="p-6 text-center rounded-lg shadow-md bg-custom-gradient">
          <div className="h-32 mb-4 bg-teal-300 rounded-lg"></div>
          <div className="flex flex-row gap-3 p-2 text-gray-200 sm:gap-5">
            <div className="flex items-center justify-center h-8 px-3 bg-white rounded-full bg-opacity-20 sm:h-10 sm:px-4">
              <p className="text-sm">Memmet Store</p>
            </div>
            <div className="flex items-center justify-center h-8 px-3 bg-white rounded-full bg-opacity-20 sm:h-10 sm:px-4">
              <CurrencyEuroIcon className="w-5 h-5 sm:w-6 sm:h-6" />
              11389
            </div>
            <div className="flex items-center justify-center w-8 h-8 bg-white rounded-full bg-opacity-20 hover:scale-125 sm:w-10 sm:h-10">
              <Link to="#">
                <PencilIcon className="w-5 h-5 sm:w-6 sm:h-6" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bedrijven;
