import React from "react";

const requests = [
  {
    id: 1,
    category: "Gemeente",
    title: "Vergunning voor evenement",
    description:
      "Aanvraag voor een vergunning om een evenement te organiseren in het stadspark.",
    status: "In behandeling",
  },
  {
    id: 2,
    category: "Politie",
    title: "Verzoek om extra patrouilles",
    description:
      "Aanvraag voor extra politiepatrouilles in de wijk vanwege recente inbraken.",
    status: "Goedgekeurd",
  },
  {
    id: 3,
    category: "Bouwbedrijf",
    title: "Bouwvergunning",
    description:
      "Aanvraag voor een bouwvergunning voor een nieuw kantoorgebouw.",
    status: "Afgewezen",
  },
  {
    id: 4,
    category: "Bank",
    title: "Leningaanvraag",
    description: "Aanvraag voor een lening om een nieuw bedrijf te starten.",
    status: "In behandeling",
  },
  // Add more requests as needed
];

const Aanvragen = () => {
  return (
    <div className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b] animate-fadeIn delay-3s">
      <h1 className="mb-6 text-3xl font-bold text-white">Aanvragen</h1>
      <div className="space-y-8">
        {requests.map((request) => (
          <div key={request.id} className="p-6 bg-[#2c2c2c] rounded-lg">
            <h2 className="mb-2 text-2xl font-semibold text-yellow-500">
              {request.title}
            </h2>
            <p className="mb-2 text-white">{request.description}</p>
            <p className="mb-2 text-gray-400">Categorie: {request.category}</p>
            <p className="text-lg font-bold text-white">
              Status: {request.status}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Aanvragen;
