import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import SideNav from "./side-nav";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex flex-col min-h-screen md:flex-row bg-custom-gradient">
      {/* Mobile Header */}
      <div className="flex items-center justify-between px-4 py-4 bg-custom-gradient md:hidden">
        <div className="flex items-center space-x-4">
          <img
            src="https://mc-heads.net/Avatar/_JustMichael"
            alt="Webpaneel afbeelding"
            className="w-12 h-12 border-4 border-white rounded-full"
          />
          <p className="text-xl font-bold text-white">_JustMichael</p>
        </div>
        <button onClick={toggleMenu} className="text-white">
          {isMenuOpen ? (
            <XMarkIcon className="w-6 h-6" />
          ) : (
            <Bars3Icon className="w-6 h-6" />
          )}
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } md:flex flex-col animate-fadeIn md:px-6 w-full md:w-64`}>
        <SideNav
          onSelect={() => setIsMenuOpen(false)} // Sluit menu na selectie
        />
      </div>

      {/* Main content */}
      <main className="flex-1 px-6 py-12 mx-auto bg-[#1b1b1b] animate-fadeIn delay-3s w-full">
        <Outlet />
      </main>
    </div>
  );
};

export default Dashboard;
