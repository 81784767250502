import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import React from "react";

const Hero = () => {
  return (
    <div className="relative bg-gradient-to-b from-[#3B198D] via-[#4a1cb8] to-[#00022f]">
      <div className="relative z-20 flex flex-col items-center px-5 py-12 mx-auto lg:py-20">
        <div className="grid items-center min-h-full gap-8 md:grid-cols-2">
          <div className="text-left">
            <div className="mb-10">
              <h1 className="text-3xl font-bold tracking-wide text-center sm:text-5xl animate-fade-in delay-1s lg:text-left">
                ECLIPSEMC
              </h1>
            </div>
            <div className="max-w-2xl">
              <div className="flex flex-col items-start justify-between mt-8">
                <p className="text-lg leading-relaxed text-center sm:text-xl xl:text-left animate-fade-in delay-3s">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum nec odio ipsum. Suspendisse cursus malesuada
                  facilisis. Integer lacinia egestas libero, sit amet
                  scelerisque metus tempus sit amet. Nullam auctor, ligula
                </p>
              </div>
              <div className="flex flex-col justify-around pt-10 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 xl:justify-start">
                <Link
                  to="#"
                  aria-label="Go to Server"
                  className="flex items-center px-6 py-3 font-medium transition-all rounded-full hover:border hover:border-white bg-eyellow sm:px-8 md:px-10 md:text-lg sm:text-base hover:bg-transparent hover:bg-primary">
                  Server
                  <BsArrowUpRight className="inline-block ml-2 text-lg" />
                </Link>
                <Link
                  to="#"
                  aria-label="Go to Discord"
                  className="flex items-center px-6 py-3 font-medium transition-all rounded-full text-purple hover:border hover:border-white bg-eyellow sm:px-8 md:px-10 md:text-lg sm:text-base hover:bg-transparent hover:bg-primary">
                  Discord
                  <BsArrowUpRight className="inline-block ml-2 text-lg" />
                </Link>
              </div>
            </div>
          </div>
          <div className="text-center text-white">
            <div className="flex justify-center animate-fade-in delay-5s">
              <img
                src="/img/logo-t.png"
                alt="Hero"
                width={400}
                height={400}
                className="h-[200px] w-[200px] sm:h-[400px] sm:w-[400px]"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center pb-10 space-y-6">
        <h1 className="text-3xl font-bold text-center text-white">
          Bezoek het webpaneel!
        </h1>
        <p className="text-center text-gray-300 sm:text-lg delay-1s">
          Een groot deel van de spelerservaring gebeurt in game. Via het paneel
          willen we aantal zaken vereenvoudigen en toegankelijker maken. Neem
          snel een kijkje!
        </p>
        <Link to="/login">
          <p className="px-6 py-3 mt-4 font-bold transition-colors transition-transform duration-300 ease-in-out transform bg-white rounded-lg shadow-lg text-primary hover:bg-gray-200 hover:scale-105">
            Bezoek het paneel
          </p>
        </Link>
      </div>
      <div className="custom-shape-divider-bottom-1727880601">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none">
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"></path>
        </svg>
      </div>
    </div>
  );
};

export default Hero;
