import React from "react";

const berichten = [
  {
    id: 1,
    titel: "Welkom bij EclipseMC!",
    tijdstempel: "2023-10-01 10:00",
    beschrijving:
      "Bedankt dat je lid bent geworden van onze community. We hopen dat je het naar je zin hebt!",
  },
  {
    id: 2,
    titel: "Serveronderhoud",
    tijdstempel: "2023-10-02 14:00",
    beschrijving:
      "Onze servers gaan op 3 oktober van 1:00 tot 7:00 uur in onderhoud.",
  },
  {
    id: 3,
    titel: "New Features Released",
    tijdstempel: "2023-10-03 09:00",
    beschrijving:
      "Bekijk de nieuwe functies die we hebben toegevoegd om uw ervaring te verbeteren.",
  },
  {
    id: 4,
    titel: "Evenement",
    tijdstempel: "2023-10-04 16:00",
    beschrijving:
      "Kom dit weekend naar een community-evenement. Veel leuke activiteiten en prijzen!",
  },
];

const Berichten = () => {
  return (
    <div className="flex-1 h-full px-6 py-12 mx-auto bg-[#1b1b1b] animate-fadeIn delay-3s">
      <h1 className="mb-6 text-3xl font-bold text-white">Berichten</h1>
      <div className="space-y-4">
        {berichten.map((berichten) => (
          <div key={berichten.id} className="p-4 bg-[#2c2c2c] rounded-lg">
            <h2 className="text-xl font-semibold text-eyellow">
              {berichten.titel}
            </h2>
            <p className="text-sm text-gray-400">{berichten.tijdstempel}</p>
            <p className="mt-2 text-white">{berichten.beschrijving}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Berichten;
